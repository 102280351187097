import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Web3ReactProvider } from "@web3-react/core";
import Web3 from "web3";
import { store } from "./store/index";
import { Provider } from "react-redux";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import { Worker } from "@react-pdf-viewer/core";

function getLibrary(provider) {
  return new Web3(provider);
}

export const serverURL = "https://strapi.creatverse.com";

export const client = new ApolloClient({
  uri: `${serverURL}/graphql`,
  cache: new InMemoryCache(),
});
ReactDOM.render(
  <Web3ReactProvider getLibrary={getLibrary}>
    <Provider store={store}>
      <ApolloProvider client={client}>
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
          <App />
        </Worker>
      </ApolloProvider>
    </Provider>
  </Web3ReactProvider>,
  document.getElementById("root")
);
