import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import Loader from "react-loader-spinner";
import LikeButton from "./LikeButton";
import Player from "video-react/lib/components/Player";
import AudioPlayer from "react-h5-audio-player";
import ControlBar from "video-react/lib/components/control-bar/ControlBar";
import "react-h5-audio-player/lib/styles.css";
import moment from "moment";
import { useUser } from "../../store/user";

const Cards = (val) => {
  const ref = useRef();
  const closeTooltip = () => ref.current.close();
  const [fileExt, setFileExt] = useState(null);
  const [play, setPlay] = useState(false);
  const { currentRPC } = useUser();

  useEffect(() => {
    const fileUrl = val.data.image
      ? val.data.image
      : val.data.collectible.image;
    const fileExt = fileUrl?.split(/[#?]/)[0].split(".").pop().trim();
    if (fileExt?.length) setFileExt(fileExt);
  }, [val]);

  const getFile = () => {
    switch (fileExt) {
      case "jpg":
      case "jpeg":
      case "png":
      case "svg":
      case "gif":
        return (
          <img
            src={val.data.image ? val.data.image : val.data.collectible.image}
            alt="gif"
            loading="lazy"
          />
        );
      case "mp3":
        return (
          <div
            style={{
              height: "100%",
              zIndex: "999",
              display: "grid",
              alignItems: "center",
            }}
          >
            <AudioPlayer
              src={val.data.image ? val.data.image : val.data.collectible.image}
              hasDefaultKeyBindings={false}
              showJumpControls={false}
            />
          </div>
        );
      case "mp4":
        return (
          <div
            onMouseOut={() => setPlay(false)}
            onMouseOver={() => setPlay(true)}
            style={{
              zIndex: "999",
              height: "100%",
              display: "grid",
              alignItems: "center",
            }}
          >
            <Player
              ref={(player) =>
                !play ? player?.video.pause() : player?.video.play()
              }
              muted
              src={val.data.image ? val.data.image : val.data.collectible.image}
              id="player"
            >
              <ControlBar
                autoHide={false}
                disableDefaultControls={true}
              ></ControlBar>
            </Player>
          </div>
        );

      //  case "pdf":
      //    return (
      //      <div style={{ height: "35vh" }}>
      //        <div
      //          style={{
      //            position: "absolute",
      //            right: "1rem",
      //            zIndex: 1,
      //          }}
      //        >
      //          {" "}
      //          <img src={pdfIcon} alt="icon of pdf" />{" "}
      //        </div>
      //        <Viewer
      //          fileUrl={imgUrl}
      //          plugins={[pageThumbnailPluginInstance, thumbnailPluginInstance]}
      //        />
      //      </div>
      //    );
      // case "fbx":
      //   return (
      //     <div style={{ height: "40vh" }}>
      //       <ReactThreeFbxViewer
      //         cameraPosition={cameraPosition}
      //         url={previewValue?.imgUrl}
      //       />
      //     </div>
      //   );
      default:
        return (
          <img
            srcSet={`"/images/content/card-pic-6@2x.jpg" 2x`}
            src={"/images/content/card-pic-6@2x.jpg"}
            alt="jpg"
          />
        );
    }
  };

  return val === null || val === undefined ? (
    <Loader type="Puff" color="#00BFFF" height={100} width={"100%"} />
  ) : (
    <div
      className={
        val.title === "Marketplace"
          ? "col-xl-3 col-lg-4 col-md-6 col-sm-6"
          : "col-xl-4 col-lg-4 col-md-6 col-sm-6"
      }
    >
      <div className="card__item four">
        <div className="card_body space-y-10">
          <div className=" creators space-x-10">
            <div className="avatars space-x-3">
              <Link to="profile">
                <img
                  src={
                    val.data?.createdBy?.avatarUrl
                      ? val.data?.createdBy?.avatarUrl
                      : val.data.collectible?.createdBy.avatarUrl
                  }
                  alt="Avatar"
                  className="avatar avatar-sm"
                />
              </Link>
              <Link to="profile">
                <p className="avatars_name txt_xs">
                  @
                  {val.data.createdBy?.name
                    ? val.data.createdBy?.name
                    : val.data.collectible?.createdBy.name}
                </p>
              </Link>
            </div>
          </div>
          <div className="card_head">
            <Link
              to={{
                pathname: `item-details/${
                  currentRPC === 1 ? "ethereum" : "polygon"
                }/${
                  val.data.token ? val.data.token : val.data.collectible?.token
                }`,
                state: val.data.id ? val.data.id : val.data.collectible?.id,
              }}
            >
              {/* <img
                src={
                  val.data.image ? val.data.image : val.data.collectible.image
                }
                alt="nftimage"
                loading="lazy"
              /> */}
              {getFile()}
            </Link>
            <span className="txt_sm">{val.data.likesCount}</span>
            <LikeButton
              item={
                val.data.collectible
                  ? val.data.collectible
                  : val.data
                  ? val.data
                  : val
              }
            />
          </div>

          <h6 className="card_title">
            {val.data.title ? val.data.title : val.data.collectible.title}
          </h6>
          <div className="card_footer d-block space-y-10">
            <div className="card_footer justify-content-between">
              <div className=" creators">
                <p className="txt_sm">
                  {" "}
                  {val.data.tokenCount
                    ? val.data.tokenCount
                    : val.data.collectible.tokenCount}{" "}
                  in stock
                </p>
              </div>
              <Link to="#">
                <p className="txt_sm">
                  Price:{" "}
                  <span className="color_green txt_sm">
                    {val.data.price
                      ? val.data.price
                      : val.data.collectible.price}{" "}
                    CREATO
                  </span>
                </p>
              </Link>
            </div>
            <div className="hr" />
            <div
              className="d-flex
                                    align-items-center
                                    space-x-10
                                    justify-content-between"
            >
              <div
                className="d-flex align-items-center
                                        space-x-5"
              >
                <i className="ri-history-line" />
                {console.log(val.data.histories)}
                <Popup
                  ref={ref}
                  trigger={
                    <button className="popup_btn">
                      <p
                        className="color_text txt_sm view_history"
                        style={{ width: "auto" }}
                      >
                        View History
                      </p>
                    </button>
                  }
                  position="bottom center"
                >
                  <div>
                    <div
                      className="popup"
                      id="popup_bid"
                      tabIndex={-1}
                      role="dialog"
                      aria-hidden="true"
                    >
                      <div>
                        <button
                          type="button"
                          className="button close"
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={closeTooltip}
                        >
                          <span aria-hidden="true"> × </span>
                        </button>
                        <div className="space-y-20">
                          <h4> History </h4>
                          {val?.data?.histories?.length > 0 ? (
                            val?.data?.histories?.map((history) => {
                              return (
                                <div className=" creator  creator_card p-6 space-x-10">
                                  <div className="avatars space-x-10">
                                    <div className="media">
                                      <img
                                        src={history?.user.avatarUrl}
                                        alt="Avatar"
                                        className="avatar avatar-md"
                                      />
                                    </div>
                                    <div>
                                      <p className="color_black">
                                        <span className="color_brand">
                                          {history.action} CREATO &nbsp;
                                        </span>
                                        by <br />
                                        <Link
                                          className="color_black txt
                            _bold"
                                          to="profile"
                                        >
                                          {history?.user?.name}
                                        </Link>
                                      </p>
                                      <span className="date color_text">
                                        {moment(history.createdAt).format(
                                          "DD/MM/YYYY hh:mm"
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <p>No History Found</p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Popup>
              </div>

              <Popup
                ref={ref}
                trigger={
                  <button className="btn btn-sm btn-primary">Place Bid</button>
                }
                position="bottom center"
              >
                <div>
                  <div
                    className="popup"
                    id="popup_bid"
                    tabIndex={-1}
                    role="dialog"
                    aria-hidden="true"
                  >
                    <div>
                      <button
                        type="button"
                        className="button close"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={closeTooltip}
                      >
                        <span aria-hidden="true">×</span>
                      </button>
                      <div className=" space-y-20">
                        <h3>Place a Bid</h3>
                        <p>
                          You must bid at least
                          <span className="color_black">15 CREATO</span>
                        </p>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="00.00  CREATO"
                        />
                        <p>
                          Enter quantity.
                          <span className="color_green">5 available</span>
                        </p>
                        <input
                          type="text"
                          className="form-control"
                          defaultValue={1}
                        />
                        <div className="hr" />
                        <div className="d-flex justify-content-between">
                          <p> You must bid at least:</p>
                          <p className="text-right color_black txt _bold">
                            67,000 CREATO
                          </p>
                        </div>
                        <div className="d-flex justify-content-between">
                          <p> service free:</p>
                          <p className="text-right color_black txt _bold">
                            0,901 CREATO
                          </p>
                        </div>
                        <div className="d-flex justify-content-between">
                          <p> Total bid amount:</p>
                          <p className="text-right color_black txt _bold">
                            56,031 CREATO
                          </p>
                        </div>
                        <Popup
                          ref={ref}
                          trigger={
                            <button className="btn btn-primary w-full">
                              Place a bid
                            </button>
                          }
                          position="bottom center"
                        >
                          <div>
                            <div
                              className="popup"
                              id="popup_bid"
                              tabIndex={-1}
                              role="dialog"
                              aria-hidden="true"
                            >
                              <div>
                                <button
                                  type="button"
                                  className="button close"
                                  data-dismiss="modal"
                                  aria-label="Close"
                                  onClick={closeTooltip}
                                >
                                  <span aria-hidden="true">×</span>
                                </button>
                                <div className="space-y-20">
                                  <h3 className="text-center">
                                    Your Bidding Successfuly Added
                                  </h3>
                                  <p className="text-center">
                                    your bid
                                    <span
                                      className="color_text txt
          _bold"
                                    >
                                      (16 CREATO)
                                    </span>
                                    has been listing to our database
                                  </p>
                                  <Link to="#" className="btn btn-dark w-full">
                                    Watch the listings
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Popup>
                      </div>
                    </div>
                  </div>
                </div>
              </Popup>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Cards;
