import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";

const initialState = {
  isAuthenticated: false,
  user: {},
  currentChain: "Ethereum",
  currentRPC: 1,
};

export const userSice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setIsAuthenticated: (state, action) => {
      state.isAuthenticated = action.payload;
    },
    setLoggedInUser: (state, action) => {
      state.user = action.payload;
    },
    handleLogout: (state) => {
      state.isAuthenticated = false;
      state.user = {};
    },
    setCurrentChain: (state, action) => {
      state.currentChain = action.payload;
    },
    setCurrentRPC: (state, action) => {
      state.currentRPC = action.payload;
    },
  },
});

export const {
  setCurrentChain,
  setIsAuthenticated,
  setLoggedInUser,
  handleLogout,
  setCurrentRPC,
} = userSice.actions;

export const userReducer = userSice.reducer;

export const useUser = () => useSelector((state) => state.user);
