import { useWeb3React } from "@web3-react/core";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { shortenAddress } from "../../../connectors/helpers";
import { handleLogout, useUser } from "../../../store/user";
const Menu = [
  {
    title: "Marketplace",
    link: "/marketplace",
  },
  {
    title: "Collections",
    link: "/collections",
  },
  {
    title: " Profile",
    link: "/profile",
  },
  {
    title: " creators",
    link: "/creators",
  },
];
function MobileMenu({ show, showChainModal }) {
  const [showDisconnectPopup, setShowDisconnectPopup] = useState(false);
  const { account, deactivate } = useWeb3React();
  const dispatch = useDispatch();
  const { currentRPC } = useUser();

  const doDisconnect = async () => {
    await deactivate();
    localStorage.removeItem("isConnected");
    localStorage.removeItem("connectedWallet");
    dispatch(handleLogout());
  };
  return (
    <div
      className={`header__mobile js-header-mobile ${
        show ? "show_header " : " "
      }header__mobile js-header-mobile`}
    >
      <div className="header__mobile__menu space-y-40">
        <ul className="d-flex space-y-20">
          {Menu.map((val, i) => (
            <li key={i}>
              {" "}
              <Link className="color_black" to={val.link}>
                {" "}
                {val.title}
              </Link>{" "}
            </li>
          ))}
        </ul>
        <div className="space-y-20">
          <div className="header__search in_mobile w-full">
            <input type="text" placeholder="Search" />
            <button className="header__result">
              <i className="ri-search-line" />
            </button>
          </div>
          <div className="">
            <button
              className="btn btn-grad btn-sm"
              style={{ marginRight: "1rem" }}
              onClick={() => {
                showChainModal();
              }}
            >
              {currentRPC === 1 ? "Ethereum" : "Polygon"}
            </button>
            {account !== null && account !== undefined ? (
              <>
                <button
                  className="btn btn-grad btn-sm"
                  onClick={() => setShowDisconnectPopup(!showDisconnectPopup)}
                >
                  {account !== null && account !== undefined
                    ? shortenAddress(account)
                    : "Connecting..."}
                </button>

                {showDisconnectPopup && (
                  <>
                    <div
                      className="backdrop"
                      onClick={() => setShowDisconnectPopup(false)}
                    ></div>
                    <div className="disconnect-popup">
                      <button
                        className="btn btn-white disconnect-btn"
                        onClick={() => doDisconnect()}
                      >
                        Disconnect
                      </button>
                    </div>
                  </>
                )}
              </>
            ) : (
              <Link className="btn btn-grad btn-sm" to="/connect-wallet">
                <i className="ri-wallet-3-line" />
                &nbsp; Connect wallet
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MobileMenu;
