import React from 'react'


const HeroUpcoming = () => {
    return (
        <div>
            <div className="hero_upcoming_projects bg_white">
                <div className="container">
                    <div className="space-y-20">
                        <h1 className="text-center">Upcoming NFT Projects</h1>
                        <p className="text-center hero__desc">You can set preferred display name, create your profile URL and manage other personal settings.</p>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default HeroUpcoming
