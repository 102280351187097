/* eslint-disable no-unused-vars */
import React, { useState, useRef } from "react";
import Footer from "../../../components/footer/Footer";
import Header from "../../../components/header/Header";
import useDocumentTitle from "../../../components/useDocumentTitle";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { uploadFile } from "react-s3";
import { userConfigs } from "../../../config";
import AvatarLogo from "../../../assets/img/avatars/avatar_3.png";
import CoverLogo from "../../../assets/img/bg/cover.jpg";

import { Link, useHistory } from "react-router-dom";
import { ACCOUNTS } from "../../../queries";
import { POST_NOTIFICATION, UPDATE_PROFILE } from "../../../mutation";
import { useQuery } from "@apollo/client";
import { useWeb3React } from "@web3-react/core";
import { useMutation } from "@apollo/client";
import Loader from "react-loader-spinner";

const EditProfile = () => {
  const { account } = useWeb3React();
  let history = useHistory();
  const { loading, error, data } = useQuery(ACCOUNTS, {
    variables: {
      where: {
        account: account,
      },
    },
  });

  const [Mutation, { update_data }] = useMutation(UPDATE_PROFILE, {
    onCompleted: (val) => {
      postNotification({
        variables: {
          input: {
            data: {
              isViewed: false,
              notification: "Your Profile updated",
              user: data?.accounts[0]?.id,
            },
          },
        },
      });
    },
  });
  const [postNotification, { data: notification_data }] =
    useMutation(POST_NOTIFICATION);
  const [newname, setName] = useState("");
  const [newcustomUrl, setcustomUrl] = useState("");
  const [newbio, setBio] = useState("");
  const [newwebsite, setWebsite] = useState("");
  const [newtwitter, setTwitter] = useState("");
  const [newfacebook, setFacebook] = useState("");
  const [newavatarUrl, setavatarUrl] = useState("");
  const [loader, setLoading] = useState(false);
  const [coverImage, setcoverImage] = useState("");
  const [coverLoader, setcoverLoader] = useState(false);

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleUrlChange = (e) => {
    setcustomUrl(e.target.value);
  };

  const handleBioChange = (e) => {
    setBio(e.target.value);
  };

  const handleWebsiteChange = (e) => {
    setWebsite(e.target.value);
  };

  const handleTwitterChange = (e) => {
    setTwitter(e.target.value);
  };

  const handleFacebookChange = (e) => {
    setFacebook(e.target.value);
  };

  const handleChangeImage = async (e) => {
    await uploadFile(e.target.files[0], userConfigs).then((res) =>
      setavatarUrl(res.location)
    );
    setLoading(false);
  };

  const handleDeleteImage = () => {
    setavatarUrl(AvatarLogo);
  };

  const handleCoverImage = async (e) => {
    await uploadFile(e.target.files[0], userConfigs).then((res) =>
      setcoverImage(res.location)
    );
    setcoverLoader(false);
  };

  const ref = useRef();
  const closeTooltip = () => ref.current.close();
  useDocumentTitle(" Edit Profile");

  return (
    <div>
      <Header />
      <div className="mb-50">
        {!loading &&
          !error &&
          data?.accounts.map((val, i) => (
            <div className="hero__profile" key={i}>
              <div className="tabs">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb default mb-0">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Edit profile
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="cover">
                {coverLoader ? (
                  <Loader
                    type="TailSpin"
                    color="black"
                    height={150}
                    width={1500}
                  />
                ) : (
                  <img
                    src={
                      coverImage === "" && (val.coverUrl === "" || val.coverUrl === null)
                        ? CoverLogo
                        : coverImage !== ""
                        ? coverImage
                        : val.coverUrl
                    }
                    alt="cover"
                  />
                )}
              </div>
            </div>
          ))}
      </div>
      {!loading &&
        !error &&
        data?.accounts.map((val, i) => (
          <div className="container" key={i}>
            <div className="mb-50">
              <h3 className="mb-30">Choice your Cover image</h3>
              <div className="row profile-img">
                <div className="col-6 col-md-2">
                  <div
                    className="
							box
							in__profile
							d-flex
							justify-content-center
							align-items-center
						"
                  >
                    <img
                      className="icon"
                      src="img/icons/upload-plus.svg"
                      alt="ImgPreview"
                    />
                    <input
                      type="file"
                      onChange={(e) => {
                        handleCoverImage(e);
                        setcoverLoader(true);
                      }}
                      accept="image/png, image/jpeg"
                    />
                  </div>
                </div>
                <div className="col-6 col-md-2">
                  <div className="pro_img is_active">
                    <img src="img/bg/cover_active.png" alt="ImgPreview" />
                  </div>
                </div>
                <div className="col-6 col-md-2">
                  <div className="pro_img">
                    <img src="img/bg/edit1.png" alt="ImgPreview" />
                  </div>
                </div>
                <div className="col-6 col-md-2">
                  <div className="pro_img">
                    <img src="img/bg/edit1.png" alt="ImgPreview" />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="avatars space-x-20 mb-30">
                <div id="profile-container">
                  {loader ? (
                    <Loader
                      type="Oval"
                      color="black"
                      height={100}
                      width={100}
                    />
                  ) : (
                    <img
                      id="profileImage"
                      src={
                        newavatarUrl === "" && val.avatarUrl === ""
                          ? AvatarLogo
                          : newavatarUrl === ""
                          ? val.avatarUrl
                          : newavatarUrl
                      }
                      alt="Avatar"
                      className="avatar avatar-lg border-0"
                    />
                  )}
                </div>
                <div className="space-x-10 d-flex">
                  <div id="boxUpload">
                    <div className="btn btn-dark">Upload New Photo</div>
                    <input
                      onChange={(e) => {
                        handleChangeImage(e);
                        setLoading(true);
                      }}
                      id="imageUpload"
                      type="file"
                      name="profile_photo"
                      placeholder="Photo"
                      required
                      capture
                    />
                  </div>
                  <div className="btn btn-white" onClick={handleDeleteImage}>
                    Delete
                  </div>
                </div>
              </div>

              <div className="box edit_box col-lg-9 space-y-30">
                <div className="row sm:space-y-20">
                  <div className="col-lg-6 account-info">
                    <h3 className="mb-20">Account info 🍉</h3>
                    <div className="form-group space-y-10 mb-0">
                      <div className="space-y-40">
                        <div className="space-y-10">
                          <span className="nameInput">Username </span>
                          <input
                            id="txtUsername"
                            type="text"
                            className="form-control"
                            defaultValue={val.name}
                            onChange={handleNameChange}
                          ></input>
                        </div>
                        <div className="space-y-10">
                          <span className="nameInput d-flex justify-content-between">
                            Email
                            <span className="txt_xs">
                              Your email for marketplace notifications
                            </span>
                          </span>
                          <div className="confirm">
                            <input
                              id="txtEmail"
                              type="text"
                              className="form-control"
                              placeholder="Enter your email"
                              defaultValue={val.website}
                              onChange={handleWebsiteChange}
                            />
                            <Link
                              to="#"
                              className="confirm-btn btn btn-dark btn-sm"
                            >
                              Confirm
                            </Link>
                          </div>
                        </div>
                        <div className="space-y-10">
                          <span className="nameInput">Custom URL</span>
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={val.customUrl}
                            onChange={handleUrlChange}
                          />
                        </div>
                        <div className="space-y-10">
                          <span className="nameInput">Bio</span>
                          <textarea
                            style={{ minHeight: 110 }}
                            placeholder="Add your bio"
                            defaultValue={val.bio}
                            onChange={handleBioChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 social-media">
                    <h3 className="mb-20">Your Social media</h3>
                    <div className="form-group space-y-10">
                      <div className="space-y-40">
                        <div className="d-flex flex-column">
                          <span className="nameInput mb-10">Facebook</span>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="facebook username"
                            defaultValue={val.facebook}
                            onChange={handleFacebookChange}
                          />
                          <Link
                            className="facebook-btn btn btn-primary mt-20 btn-sm"
                            to="#"
                          >
                            <i className="ri-facebook-circle-fill" />
                            Connect to Facebook
                          </Link>
                        </div>
                        <div className="d-flex flex-column">
                          <span className="nameInput mb-10">Twitter</span>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="twitter username"
                            defaultValue={val.twitter}
                            onChange={handleTwitterChange}
                          />
                          <Link
                            className="twitter-btn btn btn-primary mt-20 btn-sm"
                            to="#"
                          >
                            <i className="ri-twitter-fill" />
                            Connect to Twitter
                          </Link>
                        </div>
                        <div className="d-flex flex-column">
                          <span className="nameInput mb-10">Discord</span>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="discord username"
                          />
                          <Link
                            className="discord-btn btn btn-primary mt-20 btn-sm"
                            to="#"
                          >
                            <i className="ri-discord-fill" />
                            Connect to Discord
                          </Link>
                        </div>
                      </div>
                    </div>

                    <Popup
                      ref={ref}
                      trigger={
                        <button className="btn btn-white mt-20 btn-sm">
                          <i className="ri-add-circle-line color_brand mt-5px" />
                          Add more Social media
                        </button>
                      }
                      position="bottom center"
                    >
                      <div>
                        <div
                          className="popup"
                          id="popup_bid"
                          tabIndex={-1}
                          role="dialog"
                          aria-hidden="true"
                        >
                          <div>
                            <button
                              type="button"
                              className="button close"
                              data-dismiss="modal"
                              aria-label="Close"
                              onClick={closeTooltip}
                            >
                              <span aria-hidden="true">×</span>
                            </button>
                            <div className="space-y-20">
                              <h3 className="text-center">
                                Add more Social media
                              </h3>
                              <div className="d-flex flex-column">
                                <span className="nameInput mb-10">
                                  Telegram
                                </span>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="telegram username"
                                />
                                <Link
                                  className="telegram-btn btn btn-primary mt-20  btn-sm"
                                  to="#"
                                >
                                  <i className="ri-telegram-fill" />
                                  Connect to Telegram
                                </Link>
                              </div>
                              <div className="d-flex flex-column">
                                <span className="nameInput mb-10">
                                  Instagram
                                </span>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="instagram username"
                                />
                                <Link
                                  className="instagram-btn btn btn-primary mt-20  btn-sm"
                                  to="#"
                                >
                                  <i className="ri-instagram-fill" />
                                  Connect to Instagram
                                </Link>
                              </div>
                              <div className="d-flex flex-column">
                                <span className="nameInput mb-10">TikTok</span>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="tiktok username"
                                />
                                <Link
                                  className="tiktok-btn btn btn-primary mt-20  btn-sm"
                                  to="#"
                                >
                                  <img
                                    className="mr-half"
                                    src={`img/icons/tiktok.svg`}
                                    alt="tiktok"
                                    style={{ height: 20 }}
                                  />
                                  Connect to TikTok
                                </Link>
                              </div>
                              <div className="d-flex flex-column">
                                <span className="nameInput mb-10">Youtube</span>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="youtube username"
                                />
                                <Link
                                  className="youtube-btn btn btn-primary mt-20  btn-sm"
                                  to="#"
                                >
                                  <i className="ri-youtube-fill" />
                                  Connect to Youtube
                                </Link>
                              </div>
                              <div className="d-flex flex-column">
                                <span className="nameInput mb-10">Medium</span>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="medium username"
                                />
                                <Link
                                  className="medium-btn btn btn-primary mt-20  btn-sm"
                                  to="#"
                                >
                                  <img
                                    src={`img/icons/medium.svg`}
                                    alt="tiktok"
                                    style={{ height: 21 }}
                                  />
                                  Connect to Medium
                                </Link>
                              </div>
                              <Link
                                className="discord-btn btn btn-primary w-100"
                                to="#"
                              >
                                Save
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Popup>
                  </div>
                </div>
                <div className="hr" />
                <p className="color_black">
                  To update your settings you should sign message through your
                  wallet. Click 'Update profile' then sign the message.
                </p>
                <div>
                  <button
                    className="btn btn-grad"
                    onClick={(e) => {
                      e.preventDefault();
                      Mutation({
                        variables: {
                          input: {
                            where: {
                              id: val.id,
                            },
                            data: {
                              name: newname === "" ? val.name : newname,
                              customUrl:
                                newcustomUrl === ""
                                  ? val.customUrl
                                  : newcustomUrl,
                              bio: newbio === "" ? val.bio : newbio,
                              website:
                                newwebsite === "" ? val.website : newwebsite,
                              twitter:
                                newtwitter === "" ? val.newtwitter : newtwitter,
                              facebook:
                                newfacebook === "" ? val.facebook : newfacebook,
                              avatarUrl:
                                newavatarUrl === ""
                                  ? val.avatarUrl
                                  : newavatarUrl,
                              coverUrl:
                                coverImage === "" ? val.coverUrl : coverImage,
                            },
                          },
                        },
                      });
                      history.push("/profile");
                    }}
                    disabled={newavatarUrl !== AvatarLogo ? false : true}
                  >
                    Update Profile
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
      <Footer />
    </div>
  );
};

export default EditProfile;
