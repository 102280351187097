/* eslint-disable no-empty-pattern */
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Header from "../../../components/header/Header";
import useDocumentTitle from "../../../components/useDocumentTitle";
import { DATA_COLLECTIBLES, POST_NOTIFICATION } from "../../../mutation";
import { useMutation } from "@apollo/client";
import { useWeb3React } from "@web3-react/core";
import { userConfigs } from "../../../config";
import { uploadFile } from "react-s3";
import Loader from "react-loader-spinner";
import AvatarLogo from "../../../assets/img/avatars/avatar_3.png";
import { useUser } from "../../../store/user";

const Register = () => {
  useDocumentTitle(" Register");
  let history = useHistory();
  const { user: userDetails } = useUser();
  const [name, setName] = useState(userDetails?.firstName ?? null);
  const [customUrl, setcustomUrl] = useState(null);
  const [bio, setBio] = useState(null);
  const [website, setWebsite] = useState(userDetails?.email ?? null);
  const [twitter, setTwitter] = useState(null);
  const [facebook, setFacebook] = useState(null);
  const { account } = useWeb3React();
  const [imgData, setImgData] = useState("");
  const [loader, setLoading] = useState(false);

  const [Mutation, { loading, error }] = useMutation(DATA_COLLECTIBLES, {
    onCompleted: (val) => {
      postNotification({
        variables: {
          input: {
            data: {
              isViewed: false,
              notification: "Your profile created",
              user: val?.id,
            },
          },
        },
      });
    },
  });

  const [postNotification, {}] = useMutation(POST_NOTIFICATION, {
    onCompleted: (data) => {
      history.push("/profile");
    },
  });

  if (loading) return "Submitting...";
  if (error) return `Submission error! ${error.message}`;

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleUrlChange = (e) => {
    setcustomUrl(e.target.value);
  };

  const handleBioChange = (e) => {
    setBio(e.target.value);
  };

  // eslint-disable-next-line no-unused-vars
  const handleEmailChange = (e) => {
    setWebsite(e.target.value);
  };

  const handleTwitterChange = (e) => {
    setTwitter(e.target.value);
  };

  const handleFacebookChange = (e) => {
    setFacebook(e.target.value);
  };

  const handleUpload = async (e) => {
    await uploadFile(e.target.files[0], userConfigs).then((res) =>
      setImgData(res.location)
    );
    setLoading(false);
  };
  return (
    <div>
      <Header />
      <div className="edit_profile register">
        <div className="container">
          <div className="row">
            <div className="col-lg-3" />
            <div className="col-lg-9">
              <div className="right_part space-y-20">
                <h1 className="color_white"> Register new account</h1>
                <p
                  className="color_white"
                  style={{ color: "white !important" }}
                >
                  You can set preferred display name, create your profile URL
                  and manage other personal settings.
                </p>
                <div className="box edit_box w-full space-y-20">
                  <div className="row">
                    <div className="col-lg-6 account-info">
                      <div className="avatars space-x-20 mb-30">
                        <div id="profile-container">
                          {loader ? (
                            <Loader
                              type="Oval"
                              color="black"
                              height={100}
                              width={100}
                            />
                          ) : (
                            <img
                              id="profileImage"
                              src={imgData === "" ? AvatarLogo : imgData}
                              alt="Avatar"
                              className="avatar avatar-lg border-0"
                            />
                          )}
                        </div>
                        <div>
                          <h6 className="mb-1">Profile photo</h6>
                          <p className="mb-1">
                            We recommend an image of at least 400x400. Gifs work
                            too 🙌
                          </p>
                          <div id="boxUpload">
                            <div className="btn btn-sm btn-dark">Upload</div>
                            <input
                              onChange={(e) => {
                                handleUpload(e);
                                setLoading(true);
                              }}
                              id="imageUpload"
                              type="file"
                              name="profile_photo"
                              placeholder="Photo"
                              required
                              capture
                            />
                          </div>
                        </div>
                      </div>
                      <h3 className="mb-20"> 🍉 Account info </h3>
                      <div className="form-group space-y-10 mb-0">
                        <div className="space-y-20">
                          <div className="space-y-10">
                            <span className="nameInput">Display name</span>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="your name"
                              required
                              value={name}
                              onChange={handleNameChange}
                            />
                          </div>
                          <div className="space-y-10">
                            <span className="nameInput">Custom URL</span>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="creatverse.web.com/"
                              onChange={handleUrlChange}
                            />
                          </div>
                          <div className="space-y-10">
                            <span className="nameInput d-flex justify-content-between">
                              Email
                              <span className="txt_xs color_text">
                                Your email for marketplace notifications
                              </span>
                            </span>
                            <div className="confirm">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter email"
                                value={website}
                                // onChange={handleEmailChange}
                              />
                              <Link
                                to="#"
                                className="confirm-btn btn btn-dark btn-sm"
                              >
                                Confirm
                              </Link>
                            </div>
                          </div>
                          <div className="space-y-10">
                            <span className="nameInput">Bio</span>
                            <textarea
                              style={{ minHeight: 110 }}
                              placeholder="Add your bio"
                              defaultValue={"\t\t\t\t\t\t\t\t\t\t\t"}
                              onChange={handleBioChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 social-media">
                      <h3 className="mb-20">👨 Social media ‍</h3>
                      <div className="form-group space-y-10">
                        <div className="space-y-20">
                          <div className="d-flex flex-column">
                            <span className="nameInput mb-10">Facebook</span>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="facebook username"
                              onChange={handleFacebookChange}
                            />
                            <Link
                              className="facebook-btn btn btn-primary mt-10 btn-sm"
                              to="#"
                            >
                              <i className="ri-facebook-circle-fill" />
                              Connect to Facebook
                            </Link>
                          </div>
                          <div className="d-flex flex-column">
                            <span className="nameInput mb-10">Twitter</span>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="twitter username"
                              onChange={handleTwitterChange}
                            />
                            <Link
                              className="twitter-btn btn btn-primary mt-10 btn-sm"
                              to="#"
                            >
                              <i className="ri-twitter-fill" />
                              Connect to Twitter
                            </Link>
                          </div>
                          <div className="d-flex flex-column">
                            <span className="nameInput mb-10">Discord</span>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="discord username"
                            />
                            <Link
                              className="discord-btn btn btn-primary mt-10 btn-sm"
                              to="#"
                            >
                              <i className="ri-discord-fill" />
                              Connect to Discord
                            </Link>
                          </div>
                        </div>
                      </div>
                      <h3 className="mb-20 mt-40">📮 Notifications </h3>
                      <ul className="space-y-10">
                        <li className="d-flex space-x-10 switch_item">
                          <input type="checkbox" id="switch1" defaultChecked />
                          <label htmlFor="switch1">Toggle</label>
                          <span className="color_text">
                            {" "}
                            Email Notifications{" "}
                          </span>
                        </li>
                        <li className="d-flex space-x-10 switch_item">
                          <input type="checkbox" id="switch2" defaultChecked />
                          <label htmlFor="switch2">Toggle</label>
                          <span className="color_text"> New Bids </span>
                        </li>
                        <li className="d-flex space-x-10 switch_item">
                          <input type="checkbox" id="switch3" defaultChecked />
                          <label htmlFor="switch3">Toggle</label>
                          <span className="color_text"> Item Purchased </span>
                        </li>
                        <li className="d-flex space-x-10 switch_item">
                          <input type="checkbox" id="switch4" defaultChecked />
                          <label htmlFor="switch4">Toggle</label>
                          <span className="color_text"> People Followed </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="hr" />
                  <p className="color_black">
                    Please take a few minutes to read and understand Stacks
                    Terms of Service. To continue, you’ll need to accept the
                    terms of services by checking the boxes.
                  </p>
                  <button
                    className="btn btn-grad"
                    disabled={name === null || imgData === ""}
                    onClick={(e) => {
                      e.preventDefault();
                      Mutation({
                        variables: {
                          input: {
                            data: {
                              account: account,
                              name: name,
                              customUrl: customUrl,
                              bio: bio,
                              website: website,
                              twitter: twitter,
                              facebook: facebook,
                              avatarUrl: imgData,
                            },
                          },
                        },
                      });
                    }}
                  >
                    Register
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
