import { configureStore } from "@reduxjs/toolkit";
import { load, save } from "redux-localstorage-simple";
import collectibleReducer from "./collectibles/index";
import { userReducer } from "./user";
const PERSISTED_KEYS = ["user"];
export const store = configureStore({
  reducer: {
    collectibles: collectibleReducer,
    user: userReducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(save({ states: PERSISTED_KEYS })),
  preloadedState: load({ states: PERSISTED_KEYS }),
});
