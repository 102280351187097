export const tokenAddress = (chainID) => {
  switch (chainID) {
    case 42:
      return "0xad9e0574371450e6ea74f20d6f3e62b5a97a030d";
    case 97:
      return "0x8a886bdfb47ee8b6b6f3c1a022a438c862a9ea27";
    case 80001:
      return "0x3a4cce923b31c7a1f29900d62cdbbb6fc0a48d41";
    default:
      break;
  }
};

export const creatoContractAddress = (currentChainId) => {
  console.log(currentChainId, "currentChainId2");
  switch (currentChainId) {
    case 42:
      return "0x969ef9083c4bac48cacb32cdc6c06a79e5c634d8";
    case 80001:
      return "0x753Ff1f06Cf23bf2Ef4d92a5A16a8099Df998B42";
    default:
      return "0x969ef9083c4bac48cacb32cdc6c06a79e5c634d8";
  }
};

export const creatoContractBid = (currentChainId) => {
  switch (currentChainId) {
    case 42:
      return "0xcb0a12829b39832f771dBF7694A76D146912022b";
    case 80001:
      return "0xD3BA62b1e3A94Ee3E116733425061Ba9F41B3C9E";
    default:
      return "0xcb0a12829b39832f771dBF7694A76D146912022b";
  }
};
// export const creatoContractAddress =
//   "0x969ef9083c4bac48cacb32cdc6c06a79e5c634d8";

// export const creatoContractBid = "0xcb0a12829b39832f771dBF7694A76D146912022b"
