import { FortmaticConnector } from "@web3-react/fortmatic-connector";
import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { WalletLinkConnector } from "@web3-react/walletlink-connector";

export const Injected = new InjectedConnector({
  supportedChainIds: [1, 3, 4, 5, 42, 56, 97, 137, 80001],
});

export const fortmatic = new FortmaticConnector({
  apiKey: process?.env.REACT_APP_FORTMATIC_KEY,
  chainId: 42,
});

export const walletconnect = new WalletConnectConnector({
  infuraId: process?.env.REACT_APP_WALLETCONNECT_KEY,
  qrcode: true,
});

export const walletlink = new WalletLinkConnector({
  url: process?.env.REACT_APP_WALLETLINK_URL,
  appName: "creatverse-interface",
  supportedChainIds: [1, 3, 4, 5, 42, 10, 137, 69, 420, 80001],
});
