import React from "react";
import { Link } from "react-router-dom";
import { ACCOUNTS } from "../../queries";
import { useQuery } from "@apollo/client";
import { useWeb3React } from "@web3-react/core";
import AvatarLogo from "../../assets/img/bg/prrofile.png";
import CoverLogo from "../../assets/img/bg/cover.jpg";

const HeroProfile = () => {
  const { account } = useWeb3React();
  const { loading, error, data } = useQuery(ACCOUNTS, {
    variables: {
      where: {
        account: account,
      },
    },
  });

  return (
    <div className="mb-100">
      {account && !loading &&
        !error &&
        data?.accounts.map((val, i) => (
          <div className="hero__profile" key={i}>
            <div className="cover">
              <img
                src={val?.coverUrl ?? CoverLogo}
                alt="ImgPreview"
                onError={(e) => (e.target.src = CoverLogo)}
              />
            </div>
            <div className="infos">
              <div className="container">
                <div className="row flex-wrap align-items-center justify-content-between sm:space-y-50">
                  <div className="col-md-auto mr-20">
                    <div className="avatars d-flex space-x-20 align-items-center">
                      <div className="avatar_wrap">
                        <img
                          className="avatar avatar-lg"
                          src={
                            val.avatarUrl === "" ? AvatarLogo : val.avatarUrl
                          }
                          alt="avatar"
                        />
                      </div>
                      <h5>{val.name}</h5>
                    </div>
                  </div>
                  <div className="col-md-auto">
                    <div className="d-sm-flex flex-wrap align-items-center space-x-20 mb-20_reset d-sm-block">
                      <div className="mb-20">
                        <div className="copy" onClick={() =>
                              navigator.clipboard.writeText(account)
                            }>
                          <span
                            className="color_text">
                            {account && account.slice(0, 4)}....{account.slice(-4)}
                          </span>
                          <Link to="#">
                            {" "}
                            <i className="ri-file-copy-line color_text" />{" "}
                          </Link>
                        </div>
                      </div>
                      <div className="d-flex flex-wrap align-items-center space-x-20">
                        <div className="mb-20">
                          <Link to="#" className="btn btn-dark btn-sm">
                            {" "}
                            Follow{" "}
                          </Link>
                        </div>
                        <div className="mb-20">
                          <div className="share">
                            <div className="icon">
                              <Link to="#">
                                {" "}
                                <i className="ri-share-line" />{" "}
                              </Link>
                            </div>
                            <div className="dropdown__popup">
                              <ul className="space-y-10">
                                <li>
                                  <Link to="#">
                                    {" "}
                                    <i className="ri-facebook-line" />{" "}
                                  </Link>
                                </li>
                                <li>
                                  <Link to="#">
                                    {" "}
                                    <i className="ri-messenger-line" />{" "}
                                  </Link>
                                </li>
                                <li>
                                  <Link to="#">
                                    {" "}
                                    <i className="ri-whatsapp-line" />{" "}
                                  </Link>
                                </li>
                                <li>
                                  <Link to="#">
                                    {" "}
                                    <i className="ri-youtube-line" />{" "}
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="mb-20">
                          <div className="more">
                            <div className="icon">
                              <Link to="#">
                                {" "}
                                <i className="ri-more-fill" />{" "}
                              </Link>
                            </div>
                            <div className="dropdown__popup">
                              <ul className="space-y-10">
                                <li>
                                  <Link to="#" className="space-x-10 d-flex">
                                    <i className="ri-flag-line" />
                                    <span> Report </span>
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default HeroProfile;
