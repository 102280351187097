import React from "react";
import "reactjs-popup/dist/index.css";
import { CREATED } from "../../queries";
import { useQuery } from "@apollo/client";
import { useWeb3React } from "@web3-react/core";
import Loader from "react-loader-spinner";
import Cards from "./Card";

const CardProfile = () => {
  const { account } = useWeb3React();
  const { loading, data } = useQuery(CREATED, {
    variables: {
      where: {
        account: account,
      },
    },
  });

  return (
    <div>
      <div className="row mb-30_reset">
        {loading === true ? (
          <Loader type="Puff" color="#00BFFF" height={100} width={"100%"} />
        ) : data?.accounts[0]?.created_posts.length === 0 ? (
          <div className="hero_no_results">
            <div className="container">
              <div className="space-y-20">
                <h1 className="text-center hero__title">
                  Sorry, we couldn’t find any Created Tokens here.
                </h1>
              </div>
            </div>
          </div>
        ) : (
          data?.accounts[0].created_posts.map((val, i) => (
            <Cards data={val} key={i}/>
          ))
        )}
      </div>
    </div>
  );
};

export default CardProfile;
