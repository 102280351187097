import { creatoContractAddress, creatoContractBid } from ".";
import IERCABI from "../build/IERC20.json";
import NFTABI from "../build/NFTCore.json";
import BidABI from "../build/bid.json";

const getContract = (abi, address, web3) => {
  return new web3.eth.Contract(abi, address);
};

export const iercContract = (web3, address) => {
  return getContract(IERCABI.abi, address, web3);
};

export const coreContract = (web3, currentChainId) => {
  return getContract(NFTABI, creatoContractAddress(currentChainId), web3);
};

export const bidContract = (web3, currentChainId) => {
  return getContract(BidABI, creatoContractBid(currentChainId), web3);
};
