// import { gql, useMutation } from '@apollo/client';
import { gql } from "@apollo/client";

export const DATA_COLLECTIBLES = gql`
  mutation Mutation($input: createAccountInput) {
    createAccount(input: $input) {
      account {
        id
        account
        name
        customUrl
        bio
        website
        twitter
        facebook
        avatarUrl
      }
    }
  }
`;

export const NEWS_LETTER = gql`
  mutation Mutation($input: createSubscriptionInput) {
    createSubscription(input: $input) {
      subscription {
        id
        email
      }
    }
  }
`;

export const REGISTER = gql`
  mutation Mutation($input: createAuthorInput) {
    createAuthor(input: $input) {
      author {
        id
        firstName
        email
        vipCode
        password
        phoneNumber
        lastName
      }
    }
  }
`;
export const UPDATE_PROFILE = gql`
  mutation Mutation($input: updateAccountInput) {
    updateAccount(input: $input) {
      account {
        id
        name
        bio
        customUrl
        website
        twitter
        facebook
        avatarUrl
        coverUrl
      }
    }
  }
`;

export const CREATE_COLLECTIBLE = gql`
  mutation Mutation($input: createCollectibleInput) {
    createCollectible(input: $input) {
      collectible {
        id
        title
      }
    }
  }
`;

export const POST_LIKES = gql`
  mutation Mutation($input: updateCollectibleInput) {
    updateCollectible(input: $input) {
      collectible {
        id
        title
        token
        likes {
          id
        }
        likesCount
      }
    }
  }
`;

export const POST_NOTIFICATION = gql`
  mutation Mutation($input: createNotificationInput) {
    createNotification(input: $input) {
      notification {
        id
        isViewed
        user {
          id
        }
        notification
      }
    }
  }
`;

export const POST_MESSAGE = gql`
  mutation CreateMessage($input: createMessageInput) {
    createMessage(input: $input) {
      message {
        id
        createdAt
      }
    }
  }
`;
export const POST_BID = gql`
  mutation Mutation($input: createBidInput) {
    createBid(input: $input) {
      bid {
        id
        amount
        collectible {
          id
          title
          price
        }
      }
    }
  }
`;

export const POST_HISTORY = gql`
  mutation Mutation($input: createHistoryInput) {
    createHistory(input: $input) {
      history {
        id
        action
        user {
          id
          name
        }
        collectible {
          id
          title
          price
        }
      }
    }
  }
`;
