import React from "react";
import "../../../assets/scss/blur-bg.scss";
const BlurBg = () => {
  return (
    <div className="blur-bg-container">
      <div className="blur-com-container">
        <div className="bg-color bg-color-1"></div>
        <div className="bg-color bg-color-2"></div>
        <div className="bg-color bg-color-3"></div>
        <div className="bg-color bg-color-4"></div>
      </div>
    </div>
  );
};

export default BlurBg;
