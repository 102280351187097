/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import Footer from "../../../components/footer/Footer";
import Header from "../../../components/header/Header";
import { Link, useParams } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Countdown from "react-countdown";
import useDocumentTitle from "../../../components/useDocumentTitle";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { ACCOUNTS, COLLECTIBLE } from "../../../queries/index";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { bidContract, coreContract } from "../../../ethereum/contracts/coreLB";
import { useWeb3React } from "@web3-react/core";
import Web3 from "web3";
import { creatoContractBid } from "../../../ethereum/contracts";
import {
  acceptBid,
  placeBidHandler,
  purchaseHandler,
  setApproval,
  setForSale,
} from "../../../store/collectibles";
import BigNumber from "bignumber.js";
import {
  notifyPurchaseSuccess,
  notifyPurchaseInitiate,
  notifyPurchaseFailure,
  notifyBidSuccess,
  notifyBidInitiate,
  notifyBidFailure,
} from "../../../store/collectibles/helpers";
import Loader from "react-loader-spinner";
import LikeButton from "../../../components/cards/LikeButton";
import { POST_BID, POST_HISTORY } from "../../../mutation";

import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
} from "react-share";
import Player from "video-react/lib/components/Player";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import moment from "moment";
import { useUser } from "../../../store/user";
import { GetChainFromRPC } from "../../../connectors/helpers";
import { useCollectibles } from "../../../hooks";

// Random component
const Completionist = () => <span>auction ending soon now!</span>;
// Renderer callback with condition
const renderer = ({ hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a complete state
    return <Completionist />;
  } else {
    // Render a countdown
    return (
      <span>
        {hours} : {minutes} : {seconds}
      </span>
    );
  }
};
const ItemDetails = () => {
  const params = useParams();
  const { library, account, chainId } = useWeb3React();
  // const [ownersList, setOwnersList] = useState(null);
  const [tokenInfo, setTokenInfo] = useState({
    isLoading: true,
    isOwner: false,
    hasActiveBid: false,
    isApproved: false,
    bidDetails: {},
  });
  const [payable, setPayable] = useState("");
  const [purchaseAmount, setPurchaseAmount] = useState("");
  const [serviceFee, setServiceFee] = useState(5);
  const [serviceCost, setServiceCost] = useState(0);
  const [purchaseStatus, setPurchaseStatus] = useState("");
  const [saleLoading, setSaleLoading] = useState(false);
  const [bidAmount, setBidAmount] = useState("");
  const [bidStatus, setBidStatus] = useState("");
  const [btnText, setBtnText] = useState("Accept");
  const [accountResponse, setAcccountResponse] = useState([]);
  const [bidLoading, setBidLoading] = useState(false);
  const [buyLoading, setBuyLoading] = useState(false);
  const [shareUrl, setShareUrl] = useState("");
  const [fileExt, setFileExt] = useState(null);
  const [showWarning, setShowWarning] = useState(false);
  const { createdTokenId } = useCollectibles();
  const { currentRPC } = useUser();

  const [getCollectible, { data, loading: collectibleLoading,refetch }] = useLazyQuery(
    COLLECTIBLE,
    {
      variables: {
        where: {
          token: params.id,
          chainId: params.network === "ethereum" ? "42" : "80001",
        },
      },
    }
  );

  const [getUserData, { data: userData, loading }] = useLazyQuery(ACCOUNTS, {
    variables: {
      where: {
        account: accountResponse[1],
      },
    },
  });

  const { data: currentUserData } = useQuery(ACCOUNTS, {
    variables: {
      where: {
        account: account,
      },
    },
  });
  const [postBiddingData, { error }] = useMutation(POST_BID);
  const [postHistoryData, { error: histroyError }] = useMutation(POST_HISTORY);

  const ref = useRef();
  const closeTooltip = () => ref.current.close();
  useDocumentTitle("Item Details");

  useEffect(() => {
    const fileUrl = data?.collectibles[0]?.image;
    const fileExt = fileUrl?.split(/[#?]/)[0].split(".").pop().trim();
    if (fileExt?.length) setFileExt(fileExt);
  }, [data?.collectibles[0]?.image]);

  useEffect(() => {
    if (params.id) {
      setShareUrl(
        `https://app.creatverse.com/item-details/${params.network}/${params.id}`
      );
    }
  }, [params]);

  useEffect(() => {
    console.log("PATH", window.location);
  }, [purchaseStatus]);

  useEffect(() => {
    if (account && library && data) {
      tokenContract();
    }
  }, [library, account, data, purchaseStatus]);

  useEffect(() => {
    getTokenInfo();
    if (purchaseStatus === "success") {
      setBuyLoading(false);
      notifyPurchaseSuccess();
      getTokenInfo();
      closeTooltip();
    } else if (purchaseStatus === "TransHash") {
      notifyPurchaseInitiate();
    } else if (purchaseStatus === "Failed") {
      setBuyLoading(false);
      notifyPurchaseFailure();
    }
  }, [purchaseStatus]);

  useEffect(() => {
    if (bidStatus === "success") {
      postHistory("Bid");
      postBid();
      setBidLoading(false);
      notifyBidSuccess();
      getTokenInfo();
      tokenContract();
      closeTooltip();
    } else if (bidStatus === "TransHash") {
      notifyBidInitiate();
    } else if (bidStatus === "Failed") {
      setBidLoading(false);
      notifyBidFailure();
    }
  }, [bidStatus]);

  useEffect(() => {
    if (purchaseAmount.length) {
      // let fees = (parseFloat(purchaseAmount) * serviceFee) / 100;
      // // let fee = parseFloat(purchaseAmount) + fees;
      // let fee =
      //   parseFloat(purchaseAmount) + (parseFloat(purchaseAmount) * serviceFee) / 100;
      // console.log(fee)
      // setServiceCost(fees);
      // setPayable(fee);
      let fee = new BigNumber(purchaseAmount)
        .multipliedBy(serviceFee)
        .dividedBy(100)
        .toString();

      let fees = new BigNumber(purchaseAmount).plus(fee).toString();

      if (fee !== "0" && fees !== "0") {
        setServiceCost(fee);
        setPayable(fees);
      }
    } else if (bidAmount.length) {
      let fee = new BigNumber(bidAmount)
        .multipliedBy(serviceFee)
        .dividedBy(100)
        .toString();
      let fees = new BigNumber(bidAmount).plus(fee).toString();

      if (fee !== "0" && fees !== "0") {
        setServiceCost(fee);
        setPayable(fees);
      }
    }
  }, [purchaseAmount, bidAmount, serviceFee]);

  useEffect(() => {
    data?.collectibles[0]?.id === undefined && refetch()
    getCollectible();
  }, [data, createdTokenId]);

  useEffect(() => {
    let currentChainId = GetChainFromRPC[currentRPC];
    if (data?.collectibles.length > 0 && currentRPC) {
      data.collectibles[0].chainId === currentChainId.toString()
        ? setShowWarning(false)
        : setShowWarning(true);
    }
  }, [chainId, data, currentRPC]);

  const getTokenInfo = () => {
    if (data) {
      let Contract = bidContract(library, chainId).methods;
      let cContract = coreContract(library, chainId).methods;
      let token = data.collectibles[0]?.token;
      cContract
        .getOwners(token)
        .call()
        .then(async (owners) => {
          let _ownersList = [];
          await owners.forEach(async (res, index) => {
            let isowner = await Contract.isOwnerOfTheToken(token, res).call();
            if (isowner) {
              _ownersList.push(res);
            }
            if (index === owners.length - 1) {
              // setOwnersList(_ownersList);
            }
          });
        });
    }
  };
  const tokenContract = () => {
    if (
      data.collectibles.length > 0 &&
      data.collectibles[0].createdBy !== null &&
      library &&
      account
    ) {
      let Contract = bidContract(library, chainId).methods;
      let CoreContract = coreContract(library, chainId).methods;
      let token = data.collectibles[0]?.token;
      let owner = data.collectibles[0]?.createdBy?.account;
      try {
        Promise.all([
          Contract.isOwnerOfTheToken(token, account).call(),
          Contract.hasTokenActiveBid(token, owner).call(),
          Contract.currentBidDetailsOfToken(token, owner).call(),
          CoreContract.isApprovedForAll(
            account,
            creatoContractBid(chainId)
          ).call(),
          CoreContract.getIsForSale(token, owner).call(),
          CoreContract.getPriceType(token, owner).call(),
        ])
          .then((res) => {
            console.log(res);
            return [
              {
                isLoading: false,
                isOwner: res[0],
                hasActiveBid: res[1],
                bidDetails: {
                  amount: Web3.utils.fromWei(res[2]["0"]),
                  userAddress: res[2]["1"],
                  user: res[2]["1"],
                },
                isApproved: res[3],
                isForSale: res[4],
                tokenType: res[5],
              },
              res[2]["1"],
            ];
          })
          .then((res) => {
            setAcccountResponse(res);
            getUserData();
          });
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  const handleTokenInfoData = async (res) => {
    setTokenInfo({
      ...res[0],
      bidDetails: {
        ...res[0]?.bidDetails,
        user: userData?.accounts[0],
      },
    });
  };
  useEffect(() => {
    if (accountResponse !== null && !loading) {
      handleTokenInfoData(accountResponse);
    }
  }, [accountResponse, loading, userData]);

  const handlePurchase = async () => {
    setBuyLoading(true);
    await purchaseHandler({
      payable,
      account,
      library,
      chainId,
      tokenDetails: data?.collectibles[0],
      setPurchaseStatus,
    });
    tokenContract();
    getTokenInfo();
  };

  const handleBidding = async () => {
    setBidLoading(true);
    let fullAmount = Web3.utils.toWei(bidAmount.toString());
    const updates = {
      amount: fullAmount,
      collectible: [data?.collectibles[0]?.id],
      users: [tokenInfo.bidDetails.user.id],
    };
    await placeBidHandler({
      fullAmount,
      token: data?.collectibles[0],
      library,
      account,
      payable,
      chainId,
      setBidStatus,
      updates,
    });
    // setBidAmount("");
    // setBidLoading(false);
  };
  // console.log("tokenInfo", tokenInfo);
  // console.log("currentUserData", currentUserData);
  const postBid = async () => {
    await postBiddingData({
      variables: {
        input: {
          data: {
            amount: parseFloat(bidAmount),
            users: currentUserData?.accounts[0]?.id,
            collectible: data?.collectibles[0]?.id,
          },
        },
      },
    });
    setBidAmount("");
  };

  const postHistory = async (actionData) => {
    await postHistoryData({
      variables: {
        input: {
          data: {
            user: currentUserData?.accounts[0]?.id,
            collectible: data?.collectibles[0]?.id,
            amount:
              bidAmount !== ""
                ? parseFloat(bidAmount)
                : parseFloat(tokenInfo?.bidDetails.amount),
            action: actionData,
            highestbidder: tokenInfo?.bidDetails.user.name,
          },
        },
      },
    });
  };

  // eslint-disable-next-line no-unused-vars
  const handleSetSale = async () => {
    setSaleLoading(true);
    const args = {
      library,
      chainId,
      account,
      id: data?.collectibles[0]?.token,
    };
    await setForSale(args).then((res) => {
      setSaleLoading(false);
      getTokenInfo();
      tokenContract();
    });
    setSaleLoading(false);
  };

  useEffect(() => {
    checkApprovedState();
  }, [tokenInfo.isApproved]);

  const checkApprovedState = () => {
    if (tokenInfo.isApproved) {
      setBtnText("Accept");
    } else {
      setBtnText("Approve");
    }
  };

  const handleAcceptState = () => {
    let args = {
      library,
      chainId,
      account,
    };
    let tokenInput = {
      library,
      chainId,
      account,
      tokenId: data.collectibles[0].token,
    };

    if (tokenInfo?.isApproved) {
      acceptBid(tokenInput).then((res) => tokenContract());
      postHistory("Accept");
    } else {
      setApproval(args).then((res) => tokenContract());
    }
  };

  const getFile = () => {
    switch (fileExt) {
      case "jpg":
      case "jpeg":
      case "png":
      case "gif":
      case "svg":
        return (
          <img
            className="item_img"
            src={data?.collectibles[0]?.image}
            alt="svg"
            style={{
              width: "100%",
              // height: "100%",
            }}
            loading="lazy"
          />
        );

      case "mp3":
        return (
          <div
            style={{
              height: "100%",
              zIndex: "999",
              display: "grid",
              alignItems: "center",
            }}
          >
            <AudioPlayer
              src={data?.collectibles[0]?.image}
              onPlay={(e) => {}}
              hasDefaultKeyBindings={false}
            />
          </div>
        );
      case "mp4":
        return (
          <div
            style={{
              zIndex: "999",
              height: "100%",
              display: "grid",
              alignItems: "center",
            }}
          >
            <Player>
              <source src={data?.collectibles[0]?.image} />
            </Player>
          </div>
        );

      //  case "pdf":
      //    return (
      //      <div style={{ height: "35vh" }}>
      //        <div
      //          style={{
      //            position: "absolute",
      //            right: "1rem",
      //            zIndex: 1,
      //          }}
      //        >
      //          {" "}
      //          <img src={pdfIcon} alt="icon of pdf" />{" "}
      //        </div>
      //        <Viewer
      //          fileUrl={imgUrl}
      //          plugins={[pageThumbnailPluginInstance, thumbnailPluginInstance]}
      //        />
      //      </div>
      //    );
      // case "fbx":
      //   return (
      //     <div style={{ height: "40vh" }}>
      //       <ReactThreeFbxViewer
      //         cameraPosition={cameraPosition}
      //         url={previewValue?.imgUrl}
      //       />
      //     </div>
      //   );
      default:
        return (
          <img
            srcSet={`"/images/content/card-pic-6@2x.jpg" 2x`}
            src={"/images/content/card-pic-6@2x.jpg"}
            alt="jpg"
          />
        );
    }
  };

  console.log(showWarning);
  return (
    <div className="item-details-container">
      <Header />

      {showWarning && (
        <div
          style={{
            background: "#e91e63",
            color: "white",
            textAlign: "center",
            padding: "10px",
          }}
        >
          Please switch your network to{" "}
          {currentRPC !== 1 ? "Ethereum" : "Polygon"}
        </div>
      )}

      {collectibleLoading || !data?.collectibles.length > 0 ? (
        <>
          {collectibleLoading ? (
            <div
              style={{
                height: "50vh",
                display: "grid",
                placeContent: "center",
              }}
            >
              <Loader type="Puff" color="#00BFFF" height={100} width={"100%"} />
            </div>
          ) : (
            <h3
              style={{
                display: "grid",
                placeContent: "center",
                height: "30vh",
              }}
            >
              NO DATA FOUND
            </h3>
          )}
        </>
      ) : (
        <div className="container">
          <Link to="/marketplace" className="btn btn-white btn-sm my-40">
            Back to home
          </Link>
          <div className="item_details">
            <div className="row sm:space-y-20">
              <div className="col-lg-6" style={{ height: "80vh" }}>
                {/* <img
                className="item_img"
                src={data?.collectibles[0]?.image}
                alt="ImgPreview"
              /> */}
                {getFile()}
              </div>
              <div className="col-lg-6">
                <div className="space-y-20">
                  <h3>{data?.collectibles[0]?.title}</h3>
                  <div className="d-flex justify-content-between">
                    <div className="space-x-10 d-flex align-items-center">
                      <p>1 of {data?.collectibles[0]?.tokenCount}</p>
                      {/* <Link to="#" className="likes space-x-3">
                      <i className="ri-heart-3-fill" />
                      <span className="txt_sm">
                        {data?.collectibles[0]?.likesCount ?? 0}
                      </span>
                    </Link> */}
                      <LikeButton item={data?.collectibles[0]} />
                    </div>
                    <div className="space-x-10 d-flex align-items-center">
                      <div className="share">
                        <div className="icon">
                          <Popup
                            ref={ref}
                            trigger={<ul className="ri-share-line"></ul>}
                            position="top center"
                          >
                            <div
                              className="popup"
                              id="popup_share"
                              tabIndex={-1}
                              role="dialog"
                              aria-hidden="true"
                            >
                              <button
                                type="button"
                                className="button close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={closeTooltip}
                              >
                                <span aria-hidden="true">×</span>
                              </button>
                              <div className="social_media_title">
                                {" "}
                                Share this NFT
                              </div>
                              <div className="social_media">
                                <FacebookShareButton
                                  url={shareUrl}
                                  quote={
                                    "Check out this creatverse NFT " + shareUrl
                                  }
                                >
                                  <FacebookIcon size={70} round={true} />
                                </FacebookShareButton>
                                <TwitterShareButton
                                  url={shareUrl}
                                  quote={
                                    "Check out this creatverse NFT " + shareUrl
                                  }
                                >
                                  <TwitterIcon size={70} round={true} />
                                </TwitterShareButton>
                                <TelegramShareButton
                                  url={shareUrl}
                                  quote={
                                    "Check out this creatverse NFT " + shareUrl
                                  }
                                >
                                  <TelegramIcon size={70} round={true} />
                                </TelegramShareButton>
                                <EmailShareButton
                                  url={shareUrl}
                                  quote={
                                    "Check out this creatverse NFT " + shareUrl
                                  }
                                >
                                  <EmailIcon size={70} round={true} />
                                </EmailShareButton>
                                <div
                                  className=" copy-clipboard"
                                  onClick={() =>
                                    navigator.clipboard.writeText(shareUrl)
                                  }
                                >
                                  <div className="ri-file-copy-line"></div>
                                </div>
                              </div>
                            </div>
                          </Popup>
                        </div>
                        <div className="dropdown__popup">
                          <ul className="space-y-10">
                            <li>
                              <Link to>
                                <i className="ri-facebook-line" />
                              </Link>
                            </li>
                            <li>
                              <Link to>
                                <i className="ri-messenger-line" />
                              </Link>
                            </li>
                            <li>
                              <Link to>
                                <i className="ri-whatsapp-line" />
                              </Link>
                            </li>
                            <li>
                              <Link to>
                                <i className="ri-youtube-line" />
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="more">
                        <div className="icon">
                          <Link to="#">
                            <i className="ri-more-fill" />
                          </Link>
                        </div>
                        <div className="dropdown__popup">
                          <ul className="space-y-10">
                            <li>
                              <Link
                                to="#"
                                className="space-x-10 d-flex"
                                data-toggle="modal"
                                data-target="#popup_report"
                              >
                                <i className="ri-flag-line" />
                                <span> Report </span>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <select className="select_custom btn btn-dark btn-sm">
                    <option value="A">View proof of authenticity </option>
                    <option value="B">View on IPFS</option>
                    <option value="C">View on Etherscan</option>
                  </select>

                  <div className="box">
                    <Tabs className="space-y-20">
                      <div className="d-flex justify-content-between mb-30_reset">
                        <TabList className="d-flex space-x-10 mb-30 nav-tabs">
                          <Tab className="nav-item">
                            <Link
                              className="btn btn-white btn-sm"
                              data-toggle="tab"
                              to="#tabs-1"
                              role="tab"
                            >
                              Details
                            </Link>
                          </Tab>
                          <Tab>
                            <Link
                              className="btn btn-white btn-sm"
                              data-toggle="tab"
                              to="#tabs-2"
                              role="tab"
                            >
                              Bids
                            </Link>
                          </Tab>
                          <Tab>
                            <Link
                              className="btn btn-white btn-sm"
                              data-toggle="tab"
                              to="#tabs-3"
                              role="tab"
                            >
                              History
                            </Link>
                          </Tab>
                        </TabList>
                        {/* Tab panes */}
                        <div className=" d-none d-sm-block">
                          <select className="select_custom btn btn-white btn-sm">
                            <option value="A">Recent active</option>
                            <option value="B">oldest active</option>
                            <option value="C">another active</option>
                          </select>
                        </div>
                      </div>
                      <div className="hr" />
                      <div className="tab-content">
                        <TabPanel className="active">
                          <p>{data?.collectibles[0]?.description}</p>
                        </TabPanel>
                        <TabPanel>
                          {data?.collectibles[0]?.bids.length > 0 ? (
                            <div
                              className="space-y-20"
                              style={{
                                minHeight: 0,
                                maxHeight: "14rem",
                                overflowY: "scroll",
                              }}
                            >
                              {data?.collectibles[0]?.bids.map((bid, index) => {
                                return (
                                  <div className="creator_item creator_card p-6 space-x-10">
                                    <div className="avatars space-x-10">
                                      <div className="media">
                                        {/* <Link to="profile"> */}
                                        <img
                                          src={bid?.users[0]?.avatarUrl}
                                          alt="Avatar"
                                          className="avatar avatar-md"
                                        />
                                        {/* </Link> */}
                                      </div>
                                      <div>
                                        <p className="color_black">
                                          Bidding Amount{" "}
                                          <span className="color_brand">
                                            {bid.amount} CREATO
                                          </span>{" "}
                                          by
                                          <div className="color_black txt _bold">
                                            {bid?.users[0]?.name}
                                          </div>
                                        </p>
                                        <span className="date color_text">
                                          {moment(bid.createdAt).format(
                                            "DD/MM/YYYY hh:mm"
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          ) : (
                            <p>
                              No active bids yet. Be the first to make a bid!
                            </p>
                          )}
                        </TabPanel>
                        <TabPanel>
                          <div
                            className="space-y-20"
                            style={{
                              minHeight: 0,
                              maxHeight: "14rem",
                              overflowY: "scroll",
                            }}
                          >
                            {data?.collectibles[0]?.histories.length > 0 &&
                              data?.collectibles[0]?.histories.map(
                                (history, index) => {
                                  console.log(history);
                                  return (
                                    <div className="creator_item creator_card p-6 space-x-10">
                                      <div className="avatars space-x-10">
                                        <div className="media">
                                          {/* <Link to="profile"> */}
                                          <img
                                            src={history?.user.avatarUrl}
                                            alt="Avatar"
                                            className="avatar avatar-md"
                                          />
                                          {/* </Link> */}
                                        </div>
                                        <div>
                                          <p className="color_black">
                                            <span className="color_brand">
                                              {history.action === "Bid"
                                                ? `Bidded Amount ${history.amount}`
                                                : `${history.highestbidder}'s bidding Amount ${history.amount} accepted`}
                                            </span>{" "}
                                            by
                                            <div className="color_black txt _bold">
                                              {history?.user.name}
                                            </div>
                                          </p>
                                          <span className="date color_text">
                                            {moment(history.createdAt).format(
                                              "DD/MM/YYYY hh:mm"
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                          </div>
                        </TabPanel>
                      </div>
                    </Tabs>
                  </div>
                  <div className="numbers">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="space-y-5">
                          <div className="color_text">
                            {tokenInfo.tokenType === "2" ? (
                              <>
                                {tokenInfo.isOwner && !tokenInfo.hasActiveBid
                                  ? `Minimum Bid by ${tokenInfo?.bidDetails?.user?.name}`
                                  : `Highest Bid by ${tokenInfo?.bidDetails?.user?.name}`}
                                <h4>
                                  {tokenInfo.bidDetails.amount}{" "}
                                  <span className="txt_sm color_text">
                                    CREATO/ $4769.88
                                  </span>
                                </h4>
                              </>
                            ) : (
                              <>
                                {`Created by ${
                                  data?.collectibles[0]?.createdBy?.name !==
                                  undefined
                                    ? data?.collectibles[0]?.createdBy?.name
                                    : " "
                                }`}
                                <h4>
                                  {data?.collectibles[0]?.price}{" "}
                                  <span className="txt_sm color_text">
                                    CREATO/ $4769.88
                                  </span>
                                </h4>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="space-y-5">
                          <p className="color_text">countdown</p>
                          <div className="txt_lg _bold">
                            <Countdown
                              date={Date.now() + 80000000}
                              renderer={renderer}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="hr2" />
                  <div className="creators">
                    <div className="row">
                      {/* <div className="col-lg-6">
                      <div className="avatars space-x-5">
                        <div className="media">
                          <Link to="Profile">
                            <img
                              src="img/avatars/avatar_3.png"
                              alt="Avatar"
                              className="avatar avatar-sm"
                            />
                          </Link>
                        </div>
                        <div>
                          <Link to="profile">
                            <p className="avatars_name color_black">name</p>
                          </Link>
                        </div>
                      </div>
                    </div> */}
                      {data?.collectibles[0]?.owners &&
                        data?.collectibles[0]?.owners.map((owner) => {
                          return (
                            <div className="col-lg-6">
                              <div className="avatars space-x-5">
                                <div className="media">
                                  <div className="badge">
                                    <img
                                      className="badge"
                                      src="img/icons/Badge.svg"
                                      alt="ImgPreview"
                                    />
                                  </div>
                                  <Link to="profile">
                                    <img
                                      src={owner.avatarUrl}
                                      alt="Avatar"
                                      className="avatar avatar-sm"
                                    />
                                  </Link>
                                </div>
                                <div>
                                  <Link to="profile">
                                    <p className="avatars_name color_black">
                                      {owner.name}
                                    </p>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                  <div className="d-flex space-x-20">
                    {tokenInfo.tokenType === "0" && (
                      <>
                        {!tokenInfo?.isOwner ? (
                          <>
                            {tokenInfo?.isForSale ? (
                              <Popup
                                ref={ref}
                                trigger={
                                  <button className="btn btn-lg btn-primary">
                                    Buy Now
                                  </button>
                                }
                                position="bottom center"
                              >
                                <div>
                                  <div
                                    className="popup"
                                    id="popup_bid"
                                    tabIndex={-1}
                                    role="dialog"
                                    aria-hidden="true"
                                  >
                                    <div>
                                      <button
                                        type="button"
                                        className="button close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                        onClick={closeTooltip}
                                      >
                                        <span aria-hidden="true">×</span>
                                      </button>
                                      <div className="space-y-20">
                                        <h3>Checkout</h3>
                                        <p>
                                          You are about to purchase a{" "}
                                          <span className="color_black">
                                            {data?.collectibles[0]?.title}
                                          </span>{" "}
                                          from
                                          <span className="color_black">
                                            {
                                              data?.collectibles[0]?.createdBy
                                                .name
                                            }
                                          </span>
                                        </p>
                                        <div className="space-y-10">
                                          <p>You pay</p>
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder="00.00 CREATO"
                                            value={purchaseAmount}
                                            onChange={(e) =>
                                              setPurchaseAmount(e.target.value)
                                            }
                                          />
                                        </div>
                                        {/* <div className="space-y-10">
                                <p>
                                Enter quantity.{" "}
                                <span className="color_green">
                                5 available
                                </span>
                                </p>
                                <input
                                type="text"
                                className="form-control"
                                defaultValue={1}
                                />
                              </div> */}
                                        <div className="hr" />
                                        <div className="d-flex justify-content-between">
                                          <p> Your balance:</p>
                                          <p className="text-right color_black txt _bold">
                                            {" "}
                                            67,000 CREATO{" "}
                                          </p>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                          <p> service free:</p>
                                          <p className="text-right color_black txt _bold">
                                            {" "}
                                            {serviceCost} CREATO{" "}
                                          </p>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                          <p> You will pay:</p>
                                          <p className="text-right color_black txt _bold">
                                            {" "}
                                            {payable} CREATO{" "}
                                          </p>
                                        </div>
                                        {/* <Link
                                to="https://pay.sendwyre.com/"
                                className="btn btn-primary
                                w-full"
                                aria-label="Close"
                                >
                                Add funds
                              </Link> */}
                                        <button
                                          className="btn btn-primary
                                  w-full"
                                          aria-label="Close"
                                          onClick={handlePurchase}
                                        >
                                          {buyLoading ? (
                                            <Loader
                                              type="TailSpin"
                                              color="white"
                                              height={20}
                                              width={20}
                                            />
                                          ) : (
                                            "Purchase now"
                                          )}
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Popup>
                            ) : (
                              <h3>Not for sale</h3>
                            )}
                          </>
                        ) : (
                          <h3>You are a owner of this token</h3>
                        )}
                      </>
                    )}
                    {tokenInfo.tokenType === "2" && (
                      <>
                        {!tokenInfo?.isOwner && tokenInfo?.isForSale && (
                          <Popup
                            ref={ref}
                            trigger={
                              <button className="btn btn-lg btn-grad">
                                Place Bid
                              </button>
                            }
                            position="bottom center"
                          >
                            <div>
                              <div
                                className="popup"
                                id="popup_bid"
                                tabIndex={-1}
                                role="dialog"
                                aria-hidden="true"
                              >
                                <div>
                                  <button
                                    type="button"
                                    className="button close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={closeTooltip}
                                  >
                                    <span aria-hidden="true">×</span>
                                  </button>
                                  <div className=" space-y-20">
                                    <h3>Place a Bid</h3>
                                    <p>
                                      You must bid at least &nbsp;
                                      <span className="color_black">
                                        {tokenInfo.bidDetails.amount} CREATO
                                      </span>
                                    </p>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="00.00 CREATO"
                                      value={bidAmount}
                                      onChange={(e) =>
                                        setBidAmount(e.target.value)
                                      }
                                    />
                                    {/* <p>
                                  Enter quantity.
                                  <span className="color_green">
                                    5 available
                                  </span>
                                </p>
                                <input
                                  type="text"
                                  className="form-control"
                                  defaultValue={1}
                                /> */}
                                    <div className="hr" />
                                    <div className="d-flex justify-content-between">
                                      <p> You balance:</p>
                                      <p className="text-right color_black txt _bold">
                                        67,000 CREATO
                                      </p>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <p> service fee:</p>
                                      <p className="text-right color_black txt _bold">
                                        {serviceCost} CREATO
                                      </p>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <p> Total bid amount:</p>
                                      <p className="text-right color_black txt _bold">
                                        {payable} CREATO
                                      </p>
                                    </div>

                                    <button
                                      className="btn btn-primary w-full"
                                      onClick={() => {
                                        // setHistoryAction("Bid");
                                        handleBidding();
                                      }}
                                      disabled={
                                        tokenInfo?.bidDetails.amount >=
                                        bidAmount
                                      }
                                    >
                                      {bidLoading ? (
                                        <Loader
                                          type="TailSpin"
                                          color="white"
                                          height={20}
                                          width={20}
                                        />
                                      ) : (
                                        "Place a bid"
                                      )}
                                    </button>
                                    {/* <Popup
                                    ref={ref}
                                    trigger={
                                      <button
                                        className="btn btn-primary w-full"
                                        onClick={() => handleBidding()}
                                      >
                                        Place a bid
                                      </button>
                                    }
                                    position="bottom center"
                                  >
                                    <div>
                                      <div
                                        className="popup"
                                        id="popup_bid"
                                        tabIndex={-1}
                                        role="dialog"
                                        aria-hidden="true"
                                      >
                                        <div>
                                          <button
                                            type="button"
                                            className="button close"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                            onClick={closeTooltip}
                                          >
                                            <span aria-hidden="true">×</span>
                                          </button>
                                          <div className="space-y-20">
                                            <h3 className="text-center">
                                              Your Bidding Successfuly Added
                                            </h3>
                                            <p className="text-center">
                                              your bid
                                              <span
                                                className="color_text txt
                                          _bold"
                                              >
                                                (16CREATO)
                                              </span>
                                              has been listing to our database
                                            </p>
                                            <Link
                                              to="#"
                                              className="btn btn-dark w-full"
                                            >
                                              Watch the listings
                                            </Link>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Popup> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Popup>
                        )}
                        {!tokenInfo?.isForSale && !tokenInfo?.isOwner && (
                          <h3>Token Not for sale</h3>
                        )}
                        {tokenInfo?.isOwner && (
                          <>
                            {tokenInfo?.hasActiveBid ? (
                              <button
                                className="btn btn-lg btn-grad"
                                onClick={() => {
                                  handleAcceptState();
                                }}
                              >
                                {btnText}
                              </button>
                            ) : (
                              <h3>Owner and no bids</h3>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <Footer />
    </div>
  );
};

export default ItemDetails;
