import React from "react";

const Switch = ({ className, value, setValue }) => {
  return (
    // <label className="toggle-switch">
    //   <input
    //     className="toggle-input"
    //     type="checkbox"
    //     checked={value}
    //     onChange={() => setValue(!value)}
    //   />
    //   <span className="toggle-inner">
    //     <span className="toggle-box"></span>
    //   </span>
    // </label>
    <>
      <input
        type="checkbox"
        name=""
        id=""
        onChange={() => setValue(!value)}
        style={{
          height: "2rem",
          width: "2rem",
          visibility: "visible",
        }}
        checked={value}
      />
    </>
  );
};

export default Switch;
