import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import Footer from "../../../components/footer/Footer";
import Header from "../../../components/header/Header";
import useDocumentTitle from "../../../components/useDocumentTitle";
import { HandleConnect } from "../../../connectors/helpers";
import { useWeb3React } from "@web3-react/core";
import { useLazyQuery } from "@apollo/client";
import { ACCOUNTS } from "../../../queries";
import { useUser } from "../../../store/user";

const ConnectWalllet = () => {
  const history = useHistory();
  const { account, activate, chainId } = useWeb3React();
  const [errMsg, setErrMsg] = React.useState("");
  const [getAccounts, { data }] = useLazyQuery(ACCOUNTS, {
    variables: {
      where: {
        account: account,
      },
    },
  });
  const { currentRPC } = useUser();

  useEffect(() => {
    getAccountDetails();
    doRoute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, data, chainId]);

  const getWalletList = () => {
    if (currentRPC !== 2) {
      return [
        {
          title: "coinbase",
          p: "wallet that works on both mobile and through a browser extension",
          popup: "connected",
        },
        {
          title: "metamask",
          p: "A browser extension with great flexibility. The web's popular wallet",
          popup: "error",
        },

        {
          title: "fortmatic",
          p: "wallet that allows you to  sign up with your phone number on any device",
          popup: "error",
        },
        // {
        //   title: "bitski",
        //   p: "wallet that allows you to  sign in with an email and password",
        //   popup: "connected",
        // },
        {
          title: "walletconnect",
          p: "Log in with Google,  Facebook, or other OAuth provider",
          popup: "error",
        },
      ];
    } else {
      return [
        {
          title: "coinbase",
          p: "wallet that works on both mobile and through a browser extension",
          popup: "connected",
        },
        {
          title: "metamask",
          p: "A browser extension with great flexibility. The web's popular wallet",
          popup: "error",
        },
        // {
        //   title: "bitski",
        //   p: "wallet that allows you to  sign in with an email and password",
        //   popup: "connected",
        // },
        {
          title: "walletconnect",
          p: "Log in with Google,  Facebook, or other OAuth provider",
          popup: "error",
        },
      ];
    }
  };

  // const wallets = [];

  const getAccountDetails = () => {
    if (account !== null && account !== undefined) {
      getAccounts();
    }
  };

  const doRoute = () => {
    console.log(account);
    if (
      account !== undefined &&
      account !== null &&
      chainId === (42 || 80001) &&
      data !== undefined
    ) {
      if (data.accounts.length !== 0) {
        history.push("/");
      } else {
        history.push("/register");
      }
    }
  };

  useEffect(() => {
    if (account !== null && account !== undefined) {
      if (currentRPC === 1) {
        chainId !== 42
          ? setErrMsg("Please connect to the Kovan network")
          : setErrMsg("");
      } else if (currentRPC === 2) {
        chainId !== 80001
          ? setErrMsg("Please connect to the Mumbai test network")
          : setErrMsg("");
      }
    }
  }, [currentRPC, chainId, account]);

  useDocumentTitle("Wallet ");

  return (
    <div className="effect">
      <Header />
      {errMsg && <div className="error">{errMsg}</div>}
      <div className="container">
        <div>
          <Link to="/marketplace" className="btn btn-white btn-sm mt-20">
            {" "}
            Back to home
          </Link>
          <div className="hero__wallets pt-100 pb-50">
            <div className="space-y-20 d-flex flex-column align-items-center">
              <h2 className="text-center">Connect your wallet</h2>
              <p className="text-center">
                Connect with one of available wallet providers or create a new
                wallet.
              </p>
            </div>
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-lg-9">
            <div className="wallets">
              <div className="row mb-20_reset">
                {/* <div className="col-lg-4">
                  <Link to="#" className="box in__wallet space-y-10">
                    <div className="logo">
                      <img
                        src="img/phantom.png"
                        height="89"
                        alt="logo_community"
                      />
                    </div>
                    <h5 className="text-center">phantom</h5>
                    <p className="text-center">
                      Turn your browser into a crypto wallet.
                    </p>
                  </Link>
                </div> */}
                {getWalletList().map((val, i) => (
                  <div
                    className="col-lg-4 "
                    key={i}
                    onClick={() => {
                      HandleConnect(val.title, activate);
                      localStorage.setItem("isConnected", true);
                      localStorage.setItem("connectedWallet", val.title);
                    }}
                  >
                    <div className="box in__wallet space-y-10">
                      <div className="logo">
                        <img
                          src={`img/icons/${val.title}.svg`}
                          alt="logo_community"
                        />
                      </div>
                      <h5 className="text-center">{val.title}</h5>
                      <p className="text-center">{val.p}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ConnectWalllet;
