/* eslint-disable no-empty-pattern */
import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { Link, useHistory } from "react-router-dom";
import useDocumentTitle from "../../../components/useDocumentTitle";
import Header from "../../../components/header/Header";
import { CREATE_COLLECTIBLE, POST_NOTIFICATION } from "../../../mutation";
import MainLogo from "../../../assets/img/logos/Logo.png";
import { uploadFile } from "react-s3";
import { collectiblesConfigs, itemsConfigs } from "../../../config";
import { auto } from "@popperjs/core";
import { useWeb3React } from "@web3-react/core";
import { ACCOUNTS } from "../../../queries";
import Loader from "react-loader-spinner";
import { coreContract } from "../../../ethereum/contracts/coreLB";
import Player from "video-react/lib/components/Player";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import Web3 from "web3";
import {
  createNewToken,
  resetCreatingState,
  setApproval,
  setCreateLoading,
} from "../../../store/collectibles";
import { useCollectibles } from "../../../hooks";
import { useDispatch } from "react-redux";
import { createTokenState } from "../../../store/collectibles/types";
import { NFTStorage, File } from "nft.storage";
import Switch from "../../../components/switch";
import { creatoContractBid } from "../../../ethereum/contracts";
import pdfIcon from "../../../assets/img/pdf.png";
import { Viewer } from "@react-pdf-viewer/core";
import { thumbnailPlugin } from "@react-pdf-viewer/thumbnail";
import { pageThumbnailPlugin } from "../../../PageThumbnail";
import "@react-pdf-viewer/thumbnail/lib/styles/index.css";
import { useUser } from "../../../store/user";

const Upload = () => {
  useDocumentTitle(" Upload");
  const dispatch = useDispatch();
  const history = useHistory();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [imgUrl, setImgUrl] = useState("");
  const [imgLoading, setImgLoading] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [royalties, setRoyalties] = useState(10);
  const [nftStorageUrl, setNftStorageUrl] = useState(null);
  const [tokenType, setTokenType] = useState(0);
  const [forSale, setForSale] = useState(true);
  const [numberOfTokens, setNumberOfTokens] = useState(null);
  const [fileExt, setFileExt] = useState(null);
  const { account, library, chainId } = useWeb3React();
  const { creatingTokenState, createdTokenId, createLoading } =
    useCollectibles();
  const { currentRPC } = useUser();

  const apiKey = process?.env?.REACT_APP_NFT_STORAGE_API ?? null;
  const client = new NFTStorage({ token: apiKey });
  const date = new Date();
  const endDate = () => {
    return date.setMonth(date.getMonth() + 1);
  };

  const { data: creatorAccount } = useQuery(ACCOUNTS, {
    variables: {
      where: {
        account: account,
      },
    },
  });

  console.log(createLoading);

  const [postCollectible, {}] = useMutation(CREATE_COLLECTIBLE, {
    onCompleted: (val) => {
      postNotification({
        variables: {
          input: {
            data: {
              isViewed: false,
              notification: "Token Created",
              user: creatorAccount?.accounts[0]?.id,
            },
          },
        },
      });
    },
  });

  const [postNotification, {}] = useMutation(POST_NOTIFICATION);
  const thumbnailPluginInstance = thumbnailPlugin();
  const { Cover } = thumbnailPluginInstance;
  const pageThumbnailPluginInstance = pageThumbnailPlugin({
    PageThumbnail: <Cover getPageIndex={() => 0} />,
  });

  useEffect(() => {
    if (createdTokenId) {
      history.push(
        `item-details/${currentRPC === 1 ? "ethereum" : "polygon"}/` +
          createdTokenId
      );
      localStorage.setItem("createdNow", true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createdTokenId]);

  useEffect(() => {
    console.log(chainId);
    if (account && library) {
      let CoreContract = coreContract(library, chainId).methods;
      CoreContract.isApprovedForAll(account, creatoContractBid(chainId))
        .call()
        .then((res) => {
          setIsApproved(res);
        })
        .catch((err) => {
          setIsApproved(false);
        });
    }
  }, [account, library]);

  useEffect(() => {
    const fileUrl = imgUrl;
    const fileExt = fileUrl?.split(/[#?]/)[0].split(".").pop().trim();
    if (fileExt?.length) setFileExt(fileExt);
  }, [imgUrl]);

  const handleSubmit = async () => {
    if (isApproved) {
      await dispatch(setCreateLoading(true));
      await createNewToken(
        {
          address: account,
          library: library,
          tokenAmount: 1,
          isForSale: forSale,
          price: Web3.utils.toWei(price.toString()),
          priceType: tokenType,
          royaltyPercentage: parseInt(royalties),
          startTime: date.getTime(),
          endTime: endDate(),
          chainId: chainId,
        },
        dispatch
      );
    } else {
      await dispatch(setCreateLoading(true));
      let args = {
        library,
        chainId,
        account,
      };
      setApproval(args)
        .then((res) => {
          setIsApproved(true);
          dispatch(setCreateLoading(false));
        })
        .catch((err) => {
          dispatch(setCreateLoading(false));
        });
    }
  };

  const handlePostCollectible = async () => {
    // setLoading(false);
    await postCollectible({
      variables: {
        input: {
          data: {
            title: title,
            description: description,
            price: parseFloat(price),
            image: imgUrl,
            createdBy: creatorAccount.accounts[0].id,
            token: parseInt(createdTokenId),
            tokenType: tokenType,
            tokenCount:
              localStorage.getItem("upload") === "multiple"
                ? parseInt(numberOfTokens)
                : 1,
            chainId: currentRPC === 1 ? "42" : "80001",
          },
        },
      },
    });
    dispatch(resetCreatingState());
    const jsondata = {
      description: "Minting our nafter announcement!",
      external_url: `http://app.creatverse.com/item/${parseInt(
        createdTokenId
      )}`,
      image: imgUrl,
      properties: {
        media_url: nftStorageUrl,
      },
    };
    const blob = new Blob([JSON.stringify(jsondata)], {
      type: "application/json",
    });
    const itemFile = new File([blob], `${createdTokenId}.json`, {
      type: "application/json",
    });
    uploadFile(itemFile, itemsConfigs);
  };

  const handleImgUpload = async (e) => {
    await setImgLoading(true);
    await uploadToNftStorage(e);
    await uploadFile(e.target.files[0], collectiblesConfigs).then((res) => {
      setImgUrl(res.location);
      setImgLoading(false);
    });
  };

  console.log(imgUrl, "Image url");

  async function uploadToNftStorage(e) {
    console.log(e.target.files[0]);
    try {
      const metadata = await client.store({
        name: title,
        description: `${title} description`,
        image: e.target.files[0],
      });
      const url = metadata.data.image.pathname.substring(2).split("/");
      console.log(url);
      // setNftStorageUrl(`https://${url[0]}.ipfs.dweb.link/${url[1]}`);
      // ipfs.cf-ipfs.com
      setNftStorageUrl(`https://${url[0]}.ipfs.cf-ipfs.com/${url[1]}`);
    } catch (error) {
      alert(error.message);
    }
  }

  useEffect(() => {
    if (creatingTokenState === createTokenState.SUCCESS && createdTokenId) {
      handlePostCollectible(createdTokenId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creatingTokenState, createdTokenId]);

  const getFile = () => {
    switch (fileExt) {
      case "jpg":
      case "jpeg":
      case "png":
      case "gif":
      case "svg":
        return (
          <div>
            <img
              src={imgUrl}
              alt="svg"
              style={{
                width: "100%",
                height: auto,
              }}
              loading="lazy"
            />
          </div>
        );
      case "mp3":
        return (
          <div
            style={{
              height: "60vh",
              zIndex: "999",
              display: "grid",
              alignItems: "center",
            }}
          >
            <AudioPlayer
              // autoPlay
              src={imgUrl}
              onPlay={(e) => {}}
              hasDefaultKeyBindings={false}
              // other props here
            />
          </div>
        );
      case "mp4":
        return (
          <div style={{ zIndex: "999" }}>
            <Player>
              <source src={imgUrl} />
            </Player>
          </div>
        );

      case "pdf":
        return (
          <div style={{ height: "100vh" }}>
            <div
              style={{
                position: "absolute",
                left: "10%",
                top: "10%",
                zIndex: 1,
              }}
            >
              {" "}
              <img src={pdfIcon} alt="icon of pdf" />{" "}
            </div>
            <Viewer
              fileUrl={imgUrl}
              plugins={[pageThumbnailPluginInstance, thumbnailPluginInstance]}
            />
          </div>
        );
      // case "fbx":
      //   return (
      //     <div style={{ height: "40vh" }}>
      //       <ReactThreeFbxViewer
      //         cameraPosition={cameraPosition}
      //         url={previewValue?.imgUrl}
      //       />
      //     </div>
      //   );
      default:
        return (
          <img
            srcSet={`"/images/content/card-pic-6@2x.jpg" 2x`}
            src={"/images/content/card-pic-6@2x.jpg"}
            alt="jpg"
          />
        );
    }
  };

  return (
    <div>
      <Header />
      <div className="hero__upload">
        <div className="container">
          <div className="space-y-20">
            <Link
              to="upload-type"
              className="btn btn-white btn-sm
                    switch"
            >
              {localStorage.getItem("upload") === "single"
                ? "Switch to Multiple"
                : "Switch to single"}
            </Link>
            <h2 className="title">
              Create{" "}
              {localStorage.getItem("upload") === "single"
                ? "single"
                : "multiple"}{" "}
              collectible
            </h2>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="box in__upload mb-120">
          <div className="row">
            <div className="col-lg-6">
              {imgLoading ? (
                <div
                  style={{
                    height: "100%",
                    display: "grid",
                    placeContent: "center",
                  }}
                >
                  <Loader
                    type="Puff"
                    color="#00BFFF"
                    height={200}
                    width={200}
                  />
                </div>
              ) : (
                <>
                  {imgUrl !== "" ? (
                    <div style={{ height: "100%", position: "relative" }}>
                      {getFile()}
                    </div>
                  ) : (
                    <div className="left__part space-y-40 md:mb-20 upload_file">
                      <div className="space-y-20">
                        <img
                          className="icon"
                          src={`img/icons/upload.svg`}
                          alt="upload"
                        />
                        <h5>Drag and drop your file</h5>
                        <p className="color_text">
                          PNG, GIF, WEBP, MP4 or MP3. Max 100mb.
                        </p>
                      </div>
                      <div className="space-y-20">
                        <p className="color_text">or choose a file</p>
                        <Link to="#" className="btn btn-white">
                          {" "}
                          Browse files{" "}
                        </Link>
                        <input
                          type="file"
                          onChange={(e) => handleImgUpload(e)}
                        />
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
            <div className="col-lg-6">
              <div className="form-group space-y-10">
                <div className="space-y-20">
                  <div className="space-y-10">
                    <span className="nameInput">Title</span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="e. g. `creatverse design art`"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </div>
                  <div className="space-y-10">
                    <span className="nameInput">
                      Description{" "}
                      <span className="color_text">(optional) </span>
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="e. g. `creatverse design art`"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </div>
                  <div className="space-y-10">
                    <span className="nameInput">Price</span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="e. g. 0.001"
                      value={price}
                      onChange={(e) => setPrice(e.target.value)}
                    />
                  </div>
                  {localStorage.getItem("upload") === "multiple" && (
                    <div className="space-y-10">
                      <span className="nameInput">Number of copies</span>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="e. g. 1"
                        value={numberOfTokens}
                        onChange={(e) => setNumberOfTokens(e.target.value)}
                      />
                    </div>
                  )}

                  <div className="space-y-10">
                    <span className="variationInput">Royalties</span>
                    <select
                      className="form-select custom-select"
                      aria-label="Default select example"
                      onChange={(e) => setRoyalties(e.target.value)}
                    >
                      <option value={10}>10%</option>
                      <option value={20}>20%</option>
                      <option value={30}>30%</option>
                    </select>
                  </div>

                  <div
                    className="space-y-10"
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Switch value={forSale} setValue={setForSale} />
                    &emsp;
                    <span className="variationInput" style={{ margin: 0 }}>
                      Put On sale
                    </span>
                  </div>

                  <div className="space-y-10">
                    <div className="d-flex flex-column flex-md-row">
                      <button
                        style={
                          tokenType === 0
                            ? { background: "blue", color: "white" }
                            : {}
                        }
                        onClick={(e) => {
                          setTokenType(0);
                        }}
                        className="choose_collection mb-10
									mb-md-0 mr-0 mr-md-3 auction-btn"
                      >
                        Fixed Price
                      </button>
                      <button
                        style={
                          tokenType === 2
                            ? { background: "blue", color: "white" }
                            : {}
                        }
                        onClick={(e) => {
                          setTokenType(2);
                        }}
                        className="choose_collection mb-10
									mb-md-0 mr-0 mr-md-3 auction-btn"
                      >
                        Live auction
                      </button>
                    </div>
                  </div>

                  {/* <div className="space-y-10">
                    <span className="variationInput">Price</span>
                    <select
                      className="form-select custom-select"
                      aria-label="Default select example"
                      onChange={(e) => setPrice(e.target.value)}
                    >
                      <option value="00.00"> 00.00 CREATO</option>
                      <option value="01.00">01.00 CREATO</option>
                      <option value="01.00">02.00 CREATO</option>
                      <option value="10.00">10.00 CREATO</option>
                      <option value="20.00">20.00 CREATO</option>
                    </select>
                  </div> */}
                  <div className="space-y-10">
                    <span className="variationInput">Choose collection</span>
                    <div className="d-flex flex-column flex-md-row">
                      <Link
                        to
                        className="choose_collection mb-10
									mb-md-0 mr-0 mr-md-3"
                      >
                        <div className="icon">
                          <i className="ri-add-line" />
                        </div>
                        New collection
                      </Link>
                      <Link to className="choose_collection is_brand">
                        <img src={MainLogo} alt="creatverse_icon" />
                        creatverse Collection{" "}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <p className="color_black">
                <span className="color_text text-bold"> Service fee : </span>
                2.5%
              </p>{" "}
              <p className="color_black">
                <span className="color_text text-bold">
                  {" "}
                  You will receive :
                </span>
                22.425 CREATO $41,637.78
              </p>
              <p></p>
            </div>
          </div>
        </div>
      </div>
      <div className="fixed_row bottom-0 left-0 right-0">
        <div className="container">
          <div className="row content justify-content-between mb-20_reset">
            <div className="col-md-auto col-12 mb-20">
              {/* <div className="space-x-10">
                <Link
                  to="upload"
                  className="btn btn-white
						others_btn"
                >
                  {" "}
                  Cancel
                </Link>
                <Link to="#" className="btn btn-dark others_btn">
                  {" "}
                  Preview
                </Link>
              </div> */}
            </div>
            <div className="col-md-auto col-12 mb-20">
              <button
                className="btn btn-grad
					btn_create"
                onClick={() => handleSubmit()}
                style={{ width: "8.2rem" }}
              >
                {createLoading === true ? (
                  <Loader
                    type="TailSpin"
                    color="white"
                    height={20}
                    width={20}
                  />
                ) : isApproved ? (
                  "Create item"
                ) : (
                  "Approve"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Upload;
