import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { COLLECTIBLES } from "../../queries";
import Player from "video-react/lib/components/Player";
import AudioPlayer from "react-h5-audio-player";
import ControlBar from "video-react/lib/components/control-bar/ControlBar";
import "react-h5-audio-player/lib/styles.css";
import LikeButton from "./LikeButton";
import { useQuery } from "@apollo/client";
import Loader from "react-loader-spinner";
import { useUser } from "../../store/user";

const Cards1 = () => {
  const ref = useRef();
  const closeTooltip = () => ref.current.close();
  const [cardItems, setCardItems] = useState([]);
  const [whereQuery, setWhereQuery] = useState(null);
  const [play, setPlay] = useState(false);
  const { currentRPC } = useUser();

  useEffect(() => {
    setWhereQuery({ chainId: `${currentRPC === 1 ? "42" : "80001"}` });
  }, [currentRPC]);

  const { data, loading } = useQuery(COLLECTIBLES, {
    variables: {
      where: whereQuery,
    },
  });

  useEffect(() => {
    if (data?.collectibles.length > 0) {
      let manipulatedData = data.collectibles.slice(0, 8);
      setCardItems(manipulatedData);
    }
  }, [data]);

  const getFile = (url) => {
    let fileExtention = url.split(/[#?]/)[0].split(".").pop().trim();
    switch (fileExtention) {
      case "jpg":
      case "jpeg":
      case "png":
      case "svg":
      case "gif":
        return <img src={url} alt="gif" loading="lazy" />;
      case "mp3":
        return (
          <div
            style={{
              height: "100%",
              zIndex: "999",
              display: "grid",
              alignItems: "center",
            }}
          >
            <AudioPlayer
              src={url}
              hasDefaultKeyBindings={false}
              showJumpControls={false}
            />
          </div>
        );
      case "mp4":
        return (
          <div
            onMouseOut={() => setPlay(false)}
            onMouseOver={() => setPlay(true)}
            style={{
              zIndex: "999",
              height: "100%",
              display: "grid",
              alignItems: "center",
            }}
          >
            <Player
              ref={(player) =>
                !play ? player?.video.pause() : player?.video.play()
              }
              muted
              src={url}
              id="player"
            >
              <ControlBar
                autoHide={false}
                disableDefaultControls={true}
              ></ControlBar>
            </Player>
          </div>
        );

      //  case "pdf":
      //    return (
      //      <div style={{ height: "35vh" }}>
      //        <div
      //          style={{
      //            position: "absolute",
      //            right: "1rem",
      //            zIndex: 1,
      //          }}
      //        >
      //          {" "}
      //          <img src={pdfIcon} alt="icon of pdf" />{" "}
      //        </div>
      //        <Viewer
      //          fileUrl={imgUrl}
      //          plugins={[pageThumbnailPluginInstance, thumbnailPluginInstance]}
      //        />
      //      </div>
      //    );
      // case "fbx":
      //   return (
      //     <div style={{ height: "40vh" }}>
      //       <ReactThreeFbxViewer
      //         cameraPosition={cameraPosition}
      //         url={previewValue?.imgUrl}
      //       />
      //     </div>
      //   );
      default:
        return (
          <img
            srcSet={`"/images/content/card-pic-6@2x.jpg" 2x`}
            src={"/images/content/card-pic-6@2x.jpg"}
            alt="jpg"
          />
        );
    }
  };

  return (
    <div className="mt-100">
      <div className="container">
        <div className="section__head">
          <div
            className="d-md-flex
				sm:space-y-20
				space-x-20
				justify-content-between
				align-items-center"
          >
            <h2 className="section__title text-center">Explore</h2>
            <select className="select_custom btn btn-white btn-sm" disabled>
              <option value="A">Recent active</option>
              <option value="B">oldest active</option>
              <option value="C">another active</option>
            </select>
          </div>
        </div>
        {loading ? (
          <div
            style={{
              height: "20vh",
              display: "grid",
              placeContent: "center",
            }}
          >
            <Loader type="Puff" color="#00BFFF" height={100} width={"100%"} />
          </div>
        ) : (
          <div className="row">
            {cardItems.map((val, i) => (
              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6" key={i}>
                <div className="card__item four">
                  <div className="card_body space-y-10">
                    {/* =============== */}
                    <div className=" creators space-x-10">
                      <div className="avatars space-x-3">
                        <Link to="profile">
                          <img
                            src={val?.createdBy?.avatarUrl}
                            alt="Avatar"
                            className="avatar avatar-sm"
                          />
                        </Link>
                        <Link to="profile">
                          <p className="avatars_name txt_xs">
                            @{val?.createdBy?.name}
                          </p>
                        </Link>
                      </div>
                    </div>
                    <div className="card_head">
                      <Link
                        to={{
                          pathname: `item-details/${
                            val.chainId === "42" ? "ethereum" : "polygon"
                          }/${val.token}`,
                          state: val.id,
                        }}
                      >
                        {data.collectibles.length > 0 && getFile(val.image)}
                      </Link>
                      <span className="txt_sm">{val.likesCount}</span>
                      <LikeButton item={val} />
                    </div>
                    {/* =============== */}
                    <h6 className="card_title">
                      <Link className="color_black" to="item-details">
                        {val.title}
                      </Link>
                    </h6>
                    <div className="card_footer d-block space-y-10">
                      <div className="card_footer justify-content-between">
                        <div className=" creators">
                          <p className="txt_sm">{val.tokenCount} in stock</p>
                        </div>
                        <Link to="#">
                          <p className="txt_sm">
                            Price:
                            <span className="color_green txt_sm">
                              {val.price}&nbsp; CREATO
                            </span>
                          </p>
                        </Link>
                      </div>
                      <div className="hr" />
                      <div
                        className="d-flex
					  align-items-center
					  space-x-10
					  justify-content-between"
                      >
                        <div
                          className="d-flex align-items-center
						space-x-5"
                        >
                          <i className="ri-history-line" />
                          <Popup
                            ref={ref}
                            trigger={
                              <button className="popup_btn">
                                <p
                                  className="color_text txt_sm view_history"
                                  style={{ width: "auto" }}
                                >
                                  View History
                                </p>
                              </button>
                            }
                            position="bottom center"
                          >
                            <div>
                              <div
                                className="popup"
                                id="popup_bid"
                                tabIndex={-1}
                                role="dialog"
                                aria-hidden="true"
                              >
                                <div>
                                  <button
                                    type="button"
                                    className="button close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={closeTooltip}
                                  >
                                    <span aria-hidden="true">×</span>
                                  </button>
                                  <div className="space-y-20">
                                    <h4> History </h4>
                                    <div className=" creator  creator_card p-6 space-x-10">
                                      <div className="avatars space-x-10">
                                        <div className="media">
                                          <div className="badge">
                                            <img
                                              src={`img/icons/Badge.svg`}
                                              alt="Badge"
                                            />
                                          </div>
                                          <Link to="profile">
                                            <img
                                              src={`img/avatars/avatar_1.png`}
                                              alt="Avatar"
                                              className="avatar avatar-md"
                                            />
                                          </Link>
                                        </div>
                                        <div>
                                          <p className="color_black">
                                            Bid accepted
                                            <span className="color_brand">
                                              1 CREATO
                                            </span>
                                            by
                                            <Link
                                              className="color_black txt
											_bold"
                                              to="profile"
                                            >
                                              ayoub
                                            </Link>
                                          </p>
                                          <span className="date color_text">
                                            28/06/2021, 12:08
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className=" creator  creator_card p-6 space-x-10">
                                      <div className="avatars space-x-10">
                                        <div className="media">
                                          <div className="badge">
                                            <img
                                              src={`img/icons/Badge.svg`}
                                              alt="Badge"
                                            />
                                          </div>
                                          <Link to="profile">
                                            <img
                                              src={`img/avatars/avatar_2.png`}
                                              alt="Avatar"
                                              className="avatar avatar-md"
                                            />
                                          </Link>
                                        </div>
                                        <div>
                                          <p className="color_black">
                                            Bid accepted
                                            <span className="color_brand">
                                              3 CREATO
                                            </span>
                                            by
                                            <Link
                                              className="color_black txt
											_bold"
                                              to="profile"
                                            >
                                              monir
                                            </Link>
                                          </p>
                                          <span className="date color_text">
                                            22/05/2021, 12:08
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Popup>
                        </div>

                        <Popup
                          ref={ref}
                          trigger={
                            <button className="btn btn-sm btn-primary">
                              Place Bid
                            </button>
                          }
                          position="bottom center"
                        >
                          <div>
                            <div
                              className="popup"
                              id="popup_bid"
                              tabIndex={-1}
                              role="dialog"
                              aria-hidden="true"
                            >
                              <div>
                                <button
                                  type="button"
                                  className="button close"
                                  data-dismiss="modal"
                                  aria-label="Close"
                                  onClick={closeTooltip}
                                >
                                  <span aria-hidden="true">×</span>
                                </button>
                                <div className=" space-y-20">
                                  <h3>Place a Bid</h3>
                                  <p>
                                    You must bid at least
                                    <span className="color_black">
                                      15 CREATO
                                    </span>
                                  </p>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="00.00  CREATO"
                                  />
                                  <p>
                                    Enter quantity.
                                    <span className="color_green">
                                      5 available
                                    </span>
                                  </p>
                                  <input
                                    type="text"
                                    className="form-control"
                                    defaultValue={1}
                                  />
                                  <div className="hr" />
                                  <div className="d-flex justify-content-between">
                                    <p> You must bid at least:</p>
                                    <p className="text-right color_black txt _bold">
                                      67,000 CREATO
                                    </p>
                                  </div>
                                  <div className="d-flex justify-content-between">
                                    <p> service free:</p>
                                    <p className="text-right color_black txt _bold">
                                      0,901 CREATO
                                    </p>
                                  </div>
                                  <div className="d-flex justify-content-between">
                                    <p> Total bid amount:</p>
                                    <p className="text-right color_black txt _bold">
                                      56,031 CREATO
                                    </p>
                                  </div>
                                  <Popup
                                    ref={ref}
                                    trigger={
                                      <button className="btn btn-primary w-full">
                                        Place a bid
                                      </button>
                                    }
                                    position="bottom center"
                                  >
                                    <div>
                                      <div
                                        className="popup"
                                        id="popup_bid"
                                        tabIndex={-1}
                                        role="dialog"
                                        aria-hidden="true"
                                      >
                                        <div>
                                          <button
                                            type="button"
                                            className="button close"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                            onClick={closeTooltip}
                                          >
                                            <span aria-hidden="true">×</span>
                                          </button>
                                          <div className="space-y-20">
                                            <h3 className="text-center">
                                              Your Bidding Successfuly Added
                                            </h3>
                                            <p className="text-center">
                                              your bid
                                              <span
                                                className="color_text txt
      _bold"
                                              >
                                                (16 CREATO)
                                              </span>
                                              has been listing to our database
                                            </p>
                                            <Link
                                              to="#"
                                              className="btn btn-dark w-full"
                                            >
                                              Watch the listings
                                            </Link>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Popup>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Popup>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
        <div className="d-flex justify-content-center">
          <Link to="/marketplace" className="btn btn-sm btn-white">
            <i className="ri-restart-line" />
            View all items
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Cards1;
