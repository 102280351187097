import { fortmatic, Injected, walletconnect, walletlink } from "./index";

export const HandleConnect = async (wallet, activate) => {
  switch (wallet) {
    case "metamask":
      await activate(Injected);
      break;
    case "walletconnect":
      await activate(walletconnect);
      break;
    case "coinbase":
      await activate(walletlink);
      break;
    case "fortmatic":
      await activate(fortmatic);
      break;
    default:
      await activate(Injected);
  }
};

export const GetChainFromRPC = {
  1: 42,
  2: 80001,
};

export const GetNetworkFromChainId = {
  42: "ethereum",
  80001: "polygon",
};

export function shortenAddress(address, chars = 4) {
  if (address) {
    const parsed = address;
    return `${parsed.substring(0, chars + 2)}...${parsed.substring(
      42 - chars
    )}`;
  }
}
