/* eslint-disable no-empty-pattern */
import { useLazyQuery, useMutation } from "@apollo/client";
import { useWeb3React } from "@web3-react/core";
import React, { useEffect, useState } from "react";
import { POST_LIKES } from "../../mutation";
import { GET_ACCOUNT } from "../../queries";

const LikeButton = ({ item }) => {
  const [visible, setVisible] = useState(false);
  const [LikeCount, setLikeCount] = useState(0);
  const [likes, setLikes] = useState([]);
  const { account } = useWeb3React();
  const [currentUser, setCurrentUser] = useState(null);
  const [getAccount, { data: currentUserId, refetch }] = useLazyQuery(
    GET_ACCOUNT,
    {
      variables: {
        where: {
          account: account,
        },
      },
    }
  );

  useEffect(() => {
    if (currentUserId) {
      setCurrentUser(currentUserId.accounts[0]);
    } else {
      setCurrentUser(null);
    }
  }, [currentUserId]);

  useEffect(() => {
    if (account) {
      getAccount();
    }
  }, [account, getAccount]);

  const [postLike, {}] = useMutation(POST_LIKES, {
    onCompleted: (data) => {
      refetch();
    },
  });
  useEffect(() => {
    if (item?.id) {
      setLikeCount(
        item?.likesCount !== undefined && item?.likesCount !== null
          ? item.likesCount
          : 0
      );
      if (currentUser) {
        let list = item?.likes.filter((data) => data.id === currentUser.id);
        if (list && list[0]?.id) {
          setVisible(true);
        }
        setLikes(item?.likes);
      }
    }
  }, [item, currentUserId, currentUser]);

  const handlePostLike = async () => {
    const previousLikes = item.likes.map((data) => data.id);
    if (currentUser)
      await postLike({
        variables: {
          input: {
            where: {
              id: item?.id,
            },
            data: {
              likes: [...previousLikes, currentUser.id],
              likesCount: parseInt(LikeCount) + 1,
            },
          },
        },
      });
  };

  const handleDislikePost = async () => {
    if (currentUser) {
      const filteredLikes = likes
        .filter((data) => data.id !== currentUser.id)
        .map((data) => data.id);
      await postLike({
        variables: {
          input: {
            where: {
              id: item.id,
            },
            data: {
              likes: filteredLikes,
              likesCount: parseInt(LikeCount) - 1,
            },
          },
        },
      });
    }
  };
  return (
    <div
      onClick={() => {
        !visible ? handlePostLike() : handleDislikePost();
        setVisible(!visible);
      }}
      className="likes space-x-3"
    >
      <i className={`ri-heart-3-${visible ? "fill" : "line"}`} />
      <span className="txt_sm">{item?.likesCount ?? 0}</span>
    </div>
  );
};

export default LikeButton;
