import { gql } from "@apollo/client";

export const BASIC_COLLECTIBLE_FRAGMANT = gql`
  fragment BasicCollectible on Collectible {
    id
    title
    price
    highestBid
    description
    tokenCount
    likesCount
    image
    token
  }
`;

const ACCOUNT_DETAIL_FRAGMENT = gql`
  fragment AccountDetail on Account {
    id
    name
    account
    avatarUrl
  }
`;

export const COLLECTIBLES = gql`
  ${BASIC_COLLECTIBLE_FRAGMANT}
  ${ACCOUNT_DETAIL_FRAGMENT}
  query Collectibles($where: JSON, $sort: String) {
    collectibles(where: $where, sort: $sort) {
      ...BasicCollectible
      createdBy {
        ...AccountDetail
      }
      users
      likesCount
      chainId
      tokenType
      likes {
        ...AccountDetail
      }
      bids {
        id
        amount
        users {
          ...AccountDetail
        }
      }
      owners {
        ...AccountDetail
      }
      histories {
        collectible {
          id
          title
          price
        }
        user {
          ...AccountDetail
        }
        action
        createdAt
      }
      categories {
        id
        name
      }
    }
  }
`;

export const COLLECTIBLE = gql`
  ${BASIC_COLLECTIBLE_FRAGMANT}
  ${ACCOUNT_DETAIL_FRAGMENT}

  query Collectible($where: JSON) {
    collectibles(where: $where) {
      ...BasicCollectible
      createdBy {
        ...AccountDetail
      }
      users
      likesCount
      likes {
        ...AccountDetail
      }
      chainId
      tokenType
      bids {
        id
        amount
        createdAt
        users {
          ...AccountDetail
        }
      }
      owners {
        ...AccountDetail
      }
      histories {
        collectible {
          id
          title
          price
        }
        user {
          ...AccountDetail
        }
        amount
        highestbidder
        action
        createdAt
      }
    }
  }
`;

export const ACCOUNTS = gql`
  ${ACCOUNT_DETAIL_FRAGMENT}

  query Accounts($where: JSON) {
    accounts(where: $where) {
      created_posts {
        id
      }
      ...AccountDetail
      bio
      facebook
      twitter
      website
      customUrl
      coverUrl
    }
  }
`;

export const LIKED = gql`
  ${BASIC_COLLECTIBLE_FRAGMANT}
  query Liked($where: JSON) {
    accounts(where: $where) {
      name
      avatarUrl
      likedPosts {
        createdBy {
          name
          avatarUrl
          account
        }
        ...BasicCollectible
        likes {
          id
        }
      }
    }
  }
`;

export const CREATED = gql`
  ${BASIC_COLLECTIBLE_FRAGMANT}
  query Created($where: JSON) {
    accounts(where: $where) {
      name
      avatarUrl
      created_posts {
        likes {
          id
        }
        createdBy {
          name
          avatarUrl
          account
        }
        ...BasicCollectible
      }
    }
  }
`;

export const MYBID = gql`
  ${BASIC_COLLECTIBLE_FRAGMANT}
  query MyBid($where: JSON) {
    accounts(where: $where) {
      name
      avatarUrl
      biddedPosts {
        createdAt
        updatedAt
        amount
        published_at
        collectible {
          createdBy {
            name
            avatarUrl
            account
          }
          ...BasicCollectible
          likes {
            id
          }
        }
      }
    }
  }
`;

export const REGISTERED_DATA = gql`
  query RegisteredData($where: JSON) {
    authors(where: $where) {
      password
      id
      email
      userName
      vipCode
    }
  }
`;

export const GET_VIP_CODES = gql`
  query Query {
    authors {
      vipCode
    }
  }
`;

export const GET_ACCOUNT = gql`
  query Query($where: JSON) {
    accounts(where: $where) {
      id
      name
      account
      avatarUrl
    }
  }
`;

export const GET_NOTIFICATION = gql`
  query Query($where: JSON, $sort: String) {
    notifications(where: $where, sort: $sort) {
      id
      notification
      user {
        name
        id
      }
      createdAt
    }
  }
`;
