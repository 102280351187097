import { useMutation } from "@apollo/client";
import React, { useEffect } from "react";
import { useState } from "react";
import Footer from "../../../components/footer/Footer";
import Header from "../../../components/header/Header";
import useDocumentTitle from "../../../components/useDocumentTitle";
import { NEWS_LETTER } from "../../../mutation";
import { toast } from "react-toastify";

const Newsletter = () => {
  const [postSub, { data, loading, error }] = useMutation(NEWS_LETTER, {
    onError: ({ response, operation }) => {},
  });

  useEffect(() => {
    if (data !== undefined) {
      successToast();
    } else if (error !== undefined) {
      failureToast();
    }
  }, [data, error]);

  const failureToast = () => {
    toast.error("Subscription Failed", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const successToast = () => {
    toast.success("Subscription Succes", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  useDocumentTitle(" Newsletter");
  const [subscriptionMail, setSubscriptionMail] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    await postSub({
      variables: {
        input: {
          data: {
            email: subscriptionMail,
          },
        },
      },
    });
    setSubscriptionMail("");
  };
  return (
    <div>
      <Header />
      <div className="container">
        <div className="hero_newsletter box bg_white">
          <div className="row gx-5 align-items-center">
            <div className="col-lg-6 left__side">
              <div className="content space-y-20">
                <h1 className="hero__title">
                  Sign up for The NFT, creatverse newsletter!
                </h1>
                <p className="hero__desc">
                  Sign up to receive our monthly newsletter, featuring updates
                  from the team, new decentralized applications and NFT
                  projects, and trends we’re seeing in the space.
                </p>
                <form
                  onSubmit={handleSubmit}
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <input
                    placeholder="Enter your email"
                    type="email"
                    className="form-control"
                    required
                    value={subscriptionMail}
                    style={{ margin: "1rem 0", width: "100%" }}
                    onChange={(e) => setSubscriptionMail(e.target.value)}
                  />
                  <div>
                    <button
                      className="btn btn-grad"
                      disabled={subscriptionMail === "" || loading === true}
                      type="submit"
                    >
                      Subscribe
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-6 right__side">
              <img
                src={`img/bg/newsletter.png`}
                alt="newsletter"
                className="img-fluid hero__img"
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Newsletter;
