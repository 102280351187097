import React, { useEffect } from "react";
import Footer from "../../../components/footer/Footer";
import Header from "../../../components/header/Header";
import useDocumentTitle from "../../../components/useDocumentTitle";
import { Link } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { POST_MESSAGE } from "../../../mutation";
import { toast } from "react-toastify";

const Contact = () => {
  const [fullName, setFullName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [selectedCountry, setSelectedCountry] = React.useState("us");
  const [subject, setSubject] = React.useState("serviceRequest");
  useDocumentTitle(" Contact");

  const [handleSubmit, { loading, error, reset }] = useMutation(POST_MESSAGE, {
    onCompleted: () => {
      toast.success("Message sent successfully");
      setFullName("");
      setEmail("");
      setMessage("");
      setSelectedCountry("us");
      setSubject("serviceRequest");
      reset();
    },
  });

  useEffect(() => {
    if (error) toast.error("Something went wrong");
  }, [error]);

  const handleSubmitForm = () => {
    handleSubmit({
      variables: {
        input: {
          data: {
            fullName,
            email,
            message,
            selectedCountry,
            subject,
          },
        },
      },
    });
  };

  return (
    <div>
      <Header />
      <div className="contact">
        <div className="row">
          <div className="col-md-4 contact__img">
            <div className="img__wrap">
              <img src={`img/bg/contact.png`} alt="contact" />
            </div>
          </div>
          <div className="col-md-8 contact__content">
            <div className="container">
              <div className="content__wrap space-y-20">
                <div className="space-y-20">
                  <h1 className="text-left">Hi, 🖐 we are creatverse.</h1>
                  <p className="contact__desc">
                    We’re here to help and answer any question you might have.{" "}
                    <br /> We look forward to hearing from you 🙂
                  </p>
                </div>
                <div className="box is__big">
                  <div className="space-y-10 mb-0">
                    <div className="row">
                      <div className="col-sm-6 space-y-20">
                        <div className="space-y-10">
                          <span className="nameInput">Email address</span>
                          <input
                            type="email"
                            className="form-control"
                            placeholder="contact@mail.com"
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                          />
                        </div>
                        <div className="space-y-10">
                          <span className="nameInput">Select Country</span>
                          <select
                            className="form-select
                                            custom-select"
                            aria-label="Default
                                            select example"
                            onChange={(e) => setSelectedCountry(e.target.value)}
                            value={selectedCountry}
                          >
                            <option value={"us"}>United States</option>
                            <option value={"finland"}>Finland</option>
                            <option value={"norway"}>Norway</option>
                            <option value={"india"}>India</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-6 space-y-20 mt-10">
                        <div className="space-y-10">
                          <span className="nameInput">Full Name</span>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Full Name"
                            onChange={(e) => setFullName(e.target.value)}
                            value={fullName}
                          />
                        </div>
                        <div className="space-y-10">
                          <span className="nameInput">Select a Subject</span>
                          <select
                            className="form-select
                                            custom-select"
                            aria-label="Default
                                            select example"
                            value={subject}
                            onChange={(e) => setSubject(e.target.value)}
                          >
                            <option value={"serviceRequest"}>
                              Service Request
                            </option>
                            <option value={"nftItem"}>NFT items</option>
                            <option value={"wallet"}>Wallet</option>
                            <option value={"purchase"}>Purchase</option>
                            <option value={"support"}>Support</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-12 mt-20">
                        <div className="space-y-10">
                          <span className="nameInput">Message</span>
                          <textarea
                            style={{ minHeight: 110 }}
                            className="mb-0"
                            defaultValue={""}
                            onChange={(e) => setMessage(e.target.value)}
                            value={message}
                          />
                        </div>
                      </div>
                    </div>
                    <button
                      className="btn btn-primary"
                      disabled={
                        loading ||
                        fullName === "" ||
                        email === "" ||
                        message === "" ||
                        selectedCountry === "" ||
                        subject === ""
                      }
                      onClick={handleSubmitForm}
                    >
                      {loading ? "Loading..." : "Send your message"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
