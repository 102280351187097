/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import MobileMenu from "./Menu/MobileMenu";
import MegaMenu from "./Menu/MegaMenu";
import MainLogo from "../../assets/img/logos/Logo.png";
import { useWeb3React } from "@web3-react/core";
import { shortenAddress } from "../../connectors/helpers";
import { useLazyQuery } from "@apollo/client";
import { ACCOUNTS } from "../../queries";
import { useState } from "react";
import {
  handleLogout,
  setCurrentChain,
  setCurrentRPC,
  useUser,
} from "../../store/user";
import { useDispatch } from "react-redux";
import { Button } from "react-bootstrap";
import SelectChainModal from "./selectChainModal";

const HomesMenu = [
  {
    icon: "home-smile-2",
    title: " Home page 1",
    link: "/",
  },
  {
    icon: "home-2",
    title: " Home page 2",
    link: "/home-2",
  },
  {
    icon: "home-5",
    title: " Home page 3",
    link: "/home-3",
  },
];
const PagesMenu = [
  {
    title: "Marketplace",
    link: "/marketplace",
  },
  {
    title: "Collections",
    link: "/collections",
  },
  {
    title: " Profile",
    link: "/profile",
  },
  {
    title: " creators",
    link: "/creators",
  },
];

const Header = () => {
  const { account, deactivate, active, error, chainId } = useWeb3React();
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [selectChainModalShow, setSelectChainModalShow] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { currentChain, currentRPC } = useUser();
  const [showDisconnectPopup, setShowDisconnectPopup] = useState(false);
  const [getAccounts, { data }] = useLazyQuery(ACCOUNTS, {
    variables: {
      where: {
        account: account,
      },
    },
  });
  useEffect(() => {
    setShowMobileMenu(false);
  }, [window.innerWidth]);

  useEffect(() => {
    if (error !== undefined && active === false) {
      localStorage.setItem("isConnected", false);
    }
  }, [error]);
  // console.log("currentChain", currentChain,chainId)
  useEffect(() => {
    getAccounts();
    if (data !== undefined && account !== undefined && account !== null) {
      if (data?.accounts?.length === 0) {
        history.push("/register");
      }
    }
  }, [data, account]);

  const doDisconnect = async () => {
    await deactivate();
    localStorage.removeItem("isConnected");
    localStorage.removeItem("connectedWallet");
    dispatch(handleLogout());
    dispatch(setCurrentChain("Ethereum"));
    dispatch(setCurrentRPC(1));
  };

  return (
    <div>
      <header className="header__1">
        <div className="container">
          <div className="wrapper js-header-wrapper">
            <div className="header__logo">
              <Link to="/">
                <img className="header__logo" src={MainLogo} alt="logo" />
              </Link>
            </div>
            {/* ==================  */}
            <div className="header__menu">
              <ul className="d-flex space-x-20">
                {/* <li className="has_popup"> */}
                <Link className="color_black is_new" to="/">
                  Home
                  {/* <i className="ri-more-2-fill" /> */}
                </Link>
                {/* <ul className="menu__popup space-y-20">
                    {HomesMenu.map((val, i) => (
                      <li key={i}>
                        <Link to={val.link}>
                          <i className={`ri-${val.icon}-line`} />
                          {val.title}
                        </Link>
                      </li>
                    ))}
                  </ul> */}
                {/* </li> */}
                {PagesMenu.map((val, i) => (
                  <li key={i}>
                    <Link className="color_black" to={val.link}>
                      {val.title}
                    </Link>
                  </li>
                ))}

                <li className="has_popup2">
                  <Link className="color_black is_new hovered" to="/">
                    Pages <i className="ri-more-2-fill" />
                  </Link>
                  <ul className="menu__popup2 space-y-20">
                    <MegaMenu />
                  </ul>
                </li>
              </ul>
            </div>
            {/* ================= */}
            <div className="header__search">
              <input type="text" placeholder="Search" />
              <button className="header__result">
                <i className="ri-search-line" />
              </button>
            </div>
            <div
              className="header__btns btn btn-grad btn-sm"
              onClick={() => {
                setSelectChainModalShow(true);
              }}
            >
              {/* {currentChain??"Select chain"} */}
              {currentRPC === 1 ? "Ethereum" : "Polygon"}
            </div>
            <div className="header__btns">
              {account !== null && account !== undefined ? (
                <>
                  <button
                    className="btn btn-grad btn-sm"
                    onClick={() => setShowDisconnectPopup(!showDisconnectPopup)}
                  >
                    {account !== null && account !== undefined
                      ? shortenAddress(account)
                      : "Connecting..."}
                  </button>
                  {showDisconnectPopup && (
                    <>
                      <div
                        className="backdrop"
                        onClick={() => setShowDisconnectPopup(false)}
                      ></div>
                      <div className="disconnect-popup">
                        <button
                          className="btn btn-white disconnect-btn"
                          onClick={() => doDisconnect()}
                        >
                          Disconnect
                        </button>
                      </div>
                    </>
                  )}
                </>
              ) : (
                <Link className="btn btn-grad btn-sm" to="/connect-wallet">
                  <i className="ri-wallet-3-line" />
                  &nbsp; Connect wallet
                </Link>
              )}
            </div>
            <div
              className="header__burger js-header-burger"
              onClick={() => {
                console.log("showMobileMenu", showMobileMenu);
                setShowMobileMenu(!showMobileMenu);
              }}
            />
            <MobileMenu show={showMobileMenu} showChainModal={() => setSelectChainModalShow(true)} />
          </div>
        </div>
      </header>
      <SelectChainModal
        show={selectChainModalShow}
        onHide={() => setSelectChainModalShow(false)}
      />
    </div>
  );
};

export default Header;
