import React from "react";
import { Link } from "react-router-dom";

function Herocreators() {
	return (
		<div>
			<div className="hero__creators">
				<div className="container">
					<div className="row align-items-center justify-content-between">
						<div className="col-lg-6">
							<div className="space-y-20">
								<h1>Popular creators</h1>
								<p className="hero__text">
									I make art with the simple goal of giving you som CREATOing
									pleasing to look at for a few seconds.
								</p>
							</div>
						</div>
						<div className="col-lg-auto">
							<Link className="btn btn-dark" to="connect-wallet">
								{" "}
								be one of our creators
							</Link>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Herocreators;
