import React from "react";
import { Link } from "react-router-dom";
import { ACCOUNTS } from "../../queries";
import { useQuery } from "@apollo/client";
import { useWeb3React } from "@web3-react/core";

const SidebarProfile = () => {
  const { account } = useWeb3React();
  const { loading, error, data } = useQuery(ACCOUNTS, {
    variables: {
      where: {
        account: account,
      },
    },
  });

  return (
    <div className="profile__sidebar">
      {!loading &&
        !error &&
        data?.accounts.map((val, i) => (
          <div className="space-y-40" key={i}>
            <div className="space-y-10">
              <h5>About me</h5>
              <div className="box space-y-20">
                <p>{val.bio}</p>
                <div className="row">
                  <div className="col-6">
                    <span className="txt_sm color_text">Collections</span>
                    <h4>105</h4>
                  </div>
                  <div className="col-6">
                    <span className="txt_sm color_text">Creations</span>
                    <h4>{data?.accounts[0].created_posts.length}</h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="space-y-10">
              <h5>Follow me</h5>
              <div className="box">
                <ul className="social_profile space-y-10 overflow-hidden">
                  <li>
                    <Link to="#">
                      <i className="ri-facebook-line" />
                      <span className="color_text">facebook/</span>
                      {val.facebook}
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="ri-twitter-line" />
                      <span className="color_text"> twitter/</span>
                      {val.twitter}
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="ri-whatsapp-line" />
                      <span className="color_text"> whatsapp/</span>
                      
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="ri-youtube-line" />
                      <span className="color_text">youtube/</span>
                      
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        ))}
      <p className="text-center txt_sm mt-20 color_text">Since 2021</p>
    </div>
  );
};

export default SidebarProfile;
