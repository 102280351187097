import React from "react";

function HeroPrivacy() {
	return (
		<div>
			<div className="hero_privacy">
				<div className="container">
					<div className="d-flex jusitify-content-center align-items-center space-x-10">
						<h1 className="text-left">creatverse Privacy</h1>
						<i className="ri-file-text-fill privacy__icon" />
					</div>
				</div>
			</div>
		</div>
	);
}

export default HeroPrivacy;
