import { useQuery } from "@apollo/client";
import { useWeb3React } from "@web3-react/core";
import moment from "moment";
import React from "react";
import Loader from "react-loader-spinner";
import { Link } from "react-router-dom";
import Footer from "../../../components/footer/Footer";
import Header from "../../../components/header/Header";
import HeroActivity from "../../../components/hero/HeroActivity";
import { GET_ACCOUNT, GET_NOTIFICATION } from "../../../queries";

// const Notif = [
//     {
//         img: "1",
//         name: "jimena_stark loki"
//     },
//     {
//         img: "2",
//         name: "makinzi_beck loki"
//     },
//     {
//         img: "3",
//         name: "jaxon_duffy"
//     },
//     {
//         img: "4",
//         name: "darian_barry"
//     }
// ]
const Activity = () => {
  // useDocumentTitle(
  //     " Activity"
  // );
  const { account } = useWeb3React();
  const { data: currentUserId } = useQuery(GET_ACCOUNT, {
    variables: {
      where: {
        account: account,
      },
    },
  });

  const { data: notice, loading } = useQuery(GET_NOTIFICATION, {
    variables: {
      where: {
        user: {
          id: currentUserId?.accounts[0].id,
        },
      },
      skip: currentUserId?.accounts[0].id === undefined,
      sort: "createdAt:DESC",
      pollInterval: 5000,
    },
  });

  return (
    <div>
      <Header />
      <HeroActivity />
      <div className="section__activity">
        <div className="container mt-100">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-8 col-sm-10">
              <div
                className="d-flex justify-content-between align-items-center
                                mb-20"
              >
                <Link>Mark all read</Link>
                {/* <select className="select_custom btn btn-dark btn-sm">
                  <option value="A"> 📈 Trending</option>
                  <option value="B">🎉 Latest</option>
                  <option value="C">💰 Recent Sold </option>
                  <option value="C">⬆️ Heighest Price </option>
                  <option value="C">⬇️ Lowest Price </option>
                </select> */}
              </div>
              <div className="space-y-20">
                {loading ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Loader
                      type="Puff"
                      color="#00BFFF"
                      height={100}
                      width={1400}
                    />
                  </div>
                ) : notice?.notifications.length === 0 ? (
                  <p>No data found</p>
                ) : (
                  notice?.notifications.map((val, i) => (
                    <div className="box" key={i}>
                      <div className="row justify-content-between align-items-center">
                        <div className="col-auto">
                          <div className="creators_box">
                            <div className="avatars space-x-10">
                              <div className="media">
                                <img
                                  src={
                                    currentUserId?.accounts[0].avatarUrl ??
                                    "img/items/item_1.png"
                                  }
                                  onError={"img/items/item_1.png"}
                                  alt="Avatar"
                                  className="avatar avatar-md"
                                />
                              </div>
                              <div>
                                <h5 className="color_black">
                                  {val?.notification}
                                </h5>
                                <div className="d-flex space-x-10">
                                  <span className="price color_text">
                                    listed by
                                  </span>
                                  <Link
                                    to="profile"
                                    className="price color_brand"
                                  >
                                    @{val?.user?.name}
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-auto">
                          <p className="date color_text">
                            {" "}
                            {moment(new Date(val.createdAt)).calendar()}{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Activity;
