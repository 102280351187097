import React from "react";
import "reactjs-popup/dist/index.css";
import { LIKED } from "../../queries";
import { useQuery } from "@apollo/client";
import { useWeb3React } from "@web3-react/core";
import Cards from "./Card";
import Loader from "react-loader-spinner";

const LikeProfile = () => {
  const { account } = useWeb3React();
  const { loading, data } = useQuery(LIKED, {
    variables: {
      where: {
        account: account,
      },
    },
  });

  return (
    <>
      <div className="row mb-30_reset">
        {loading === true ? (
          <Loader type="Puff" color="#00BFFF" height={100} width={1000} />
        ) : data?.accounts[0].likedPosts.length === 0 ? (
          <div className="hero_no_results">
            <div className="container">
              <div className="space-y-20">
                <h1 className="text-center hero__title">
                  Sorry, we couldn’t find any Liked Tokens here.
                </h1>
              </div>
            </div>
          </div>
        ) : (
          data?.accounts[0].likedPosts.map((val, i) => (
            <Cards data={val} key={i}/>
          ))
        )}
      </div>
    </>
  );
};

export default LikeProfile;
