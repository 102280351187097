/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { setLogVerbosity, useMutation, useQuery } from "@apollo/client";
import React, { useEffect } from "react";
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import useDocumentTitle from "../../../components/useDocumentTitle";
import { REGISTER } from "../../../mutation";
import { useLazyQuery } from "@apollo/client";
import { GET_VIP_CODES, REGISTERED_DATA } from "../../../queries";
import { toast } from "react-toastify";
import {
  setIsAuthenticated,
  setLoggedInUser,
  useUser,
} from "../../../store/user";
import { useDispatch } from "react-redux";
import axios from "axios";
import Loader from "react-loader-spinner";
import { serverURL } from "../../..";
import BlurBg from "./BlurBg";
import logo from "../../../assets/img/logos/logo-trans.png";

const Login = () => {
  useDocumentTitle("Login");
  const history = useHistory();
  const dispatch = useDispatch();
  const { isAuthenticated } = useUser();
  const [initialState, setInitialState] = useState(true);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const [phoneNumber, setphoneNumber] = useState("");
  const [userVipCode, setUserVipCode] = useState("");
  const [loginLoader, setLoginLoading] = useState(false);
  const [showMainscreen, setShowMainscreen] = useState(true);
  const [showEmailScreen, setShowEmailScreen] = useState(false);
  const [showOtpScreen, setShowOtpScreen] = useState(false);
  const [showPasswordScreen, setShowPasswordScreen] = useState(false);
  const [emailForReset, setResetEmail] = useState("");
  const [otpForReset, setOtpForReset] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPasswordForReset, setConfirmPasswordForReset] = useState("");
  const [registerFunction, { data, loading, reset }] = useMutation(REGISTER, {
    onCompleted: (data) => {
      setFirstName("");
      setLastName("");
      setEmail("");
      setPassword("");
      setConfirmPassword("");
      setphoneNumber("");
      // reset();
      setInitialState(true);
      toast.success("Register Successfull");
    },
    onError: (data) => {
      toast.error("Register failed!");
    },
  });

  // const { data: codeData } = useQuery(GET_VIP_CODES);
  useEffect(() => {
    if (isAuthenticated) {
      history.push("/");
    }
  }, [isAuthenticated]);

  const handleLogin = async () => {
    if (email === "" || password === "" || userVipCode === "") {
      email === "" && toast.error("Email is Empty!");
      password === "" && toast.error("Password is Empty!");
      userVipCode === "" && toast.error("VipCode is Empty!");
      return;
    }
    setLoginLoading(true);
    axios
      .get(
        `${serverURL}/authAuthors?email=${email.toLowerCase()}&password=${password}&vipCode=${userVipCode}`
      )
      .then((res) => {
        if (res.data.status === true) {
          dispatch(setIsAuthenticated(true));
          dispatch(setLoggedInUser(res.data?.data));
        } else {
          toast.error(res.data?.message);
        }
        setLoginLoading(false);
      })
      .catch((e) => {
        toast.error(e?.message);
        setLoginLoading(false);
      });
  };

  const keyHandle = (e) => {
    if (e.key === "Enter") {
      handleLogin();
    }
  };

  const handleRegister = async () => {
    if (
      firstName === "" ||
      email === "" ||
      password === "" ||
      confirmpassword === "" ||
      confirmpassword !== password
    ) {
      firstName === "" && toast.error("FirstName is Empty!");
      email === "" && toast.error("Email is Empty!");
      password === "" && toast.error("Password is Empty!");
      confirmpassword === "" && toast.error("Confirm password is Empty!");
      confirmpassword !== password && toast.error("Passwords don't match!");
      return;
    }
    await registerFunction({
      variables: {
        input: {
          data: {
            firstName: firstName,
            lastName: lastName,
            email: email.toLowerCase(),
            password: password,
            phoneNumber: phoneNumber,
          },
        },
      },
    });
  };

  const handleSendOtp = () => {
    axios
      .post(`${serverURL}/forgotpasscode`, {
        email: emailForReset,
      })
      .then((res) => {
        console.log(res);
        setShowEmailScreen(false);
        setShowOtpScreen(true);
      })
      .catch((e) => {
        toast.error("Enter a valid email");
      });
  };

  const handleVerifyOtp = () => {
    axios
      .post(`${serverURL}/verifyOtp`, {
        email: emailForReset,
        otp: parseInt(otpForReset),
      })
      .then((res) => {
        if (res?.data.status === true) {
          setShowOtpScreen(false);
          setShowPasswordScreen(true);
        } else {
          toast.error(res?.data?.message);
        }
      })
      .catch((e) => {
        toast.error(e?.message);
      });
  };

  const handleUpdatePassword = async () => {
    await axios
      .post(`${serverURL}/updatepassword`, {
        email: emailForReset,
        password: newPassword,
        otp: parseInt(otpForReset),
      })
      .then((res) => {
        if (res?.data.status === true) {
          toast.success("Password updated successfully");
          setShowPasswordScreen(false);
          setShowMainscreen(true);
        } else {
          toast.error(res?.data?.message);
        }
      })
      .catch((e) => {
        toast.error(e?.message);
      });
  };

  return (
    <div>
      <div className="edit_profile register login">
        <div className="container" style={{ minHeight: "100vh" }}>
          <div className="row">
            <div className="col-lg-3" />
            <div className="col-lg-6">
              <div className="right_part ">
                <BlurBg />
                <div className="logo-container">
                  <img src={logo} alt="icon" />
                  <h1 className="createverse-title">Creatverse </h1>
                </div>
                {showMainscreen && (
                  <>
                    <p
                      // className="color_white"
                      style={{
                        color: "#7c7ef7 !important",
                        textAlign: "center",
                      }}
                    >
                      {!initialState
                        ? "Do you have an account?"
                        : "Don't have an account yet?"}
                      <button
                        className="pageChange-btn"
                        onClick={() => setInitialState(!initialState)}
                        style={{ zIndex: "999", position: "relative" }}
                      >
                        {initialState ? "Register" : "Login"}
                      </button>
                    </p>
                    <div className="box edit_box w-full space-y-20">
                      {!initialState && (
                        <>
                          <div className="space-y-10">
                            <span className="nameInput">First Name</span>
                            <div className="confirm">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter your firstName"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="space-y-10">
                            <span className="nameInput">Last Name</span>
                            <div className="confirm">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter your lastName"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                              />
                            </div>
                          </div>
                        </>
                      )}
                      <div className="space-y-10">
                        <span className="nameInput">Email </span>
                        <div className="confirm">
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Enter email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="space-y-10">
                        <span className="nameInput">Password</span>
                        <div className="confirm">
                          <input
                            type="password"
                            className="form-control"
                            placeholder="Enter your password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </div>
                      </div>
                      {!initialState && (
                        <>
                          <div className="space-y-10">
                            <span className="nameInput">Confirm Password</span>
                            <div className="confirm">
                              <input
                                type="password"
                                className="form-control"
                                placeholder="Enter your confirm password"
                                value={confirmpassword}
                                onChange={(e) =>
                                  setConfirmPassword(e.target.value)
                                }
                              />
                            </div>
                          </div>
                          <div className="space-y-10">
                            <span className="nameInput">Phone Number</span>
                            <div className="confirm">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter your phoneNumber"
                                value={phoneNumber}
                                onChange={(e) => setphoneNumber(e.target.value)}
                              />
                            </div>
                          </div>
                        </>
                      )}
                      {initialState && (
                        <div className="space-y-10">
                          <span className="nameInput">VIP Code</span>
                          <div className="confirm">
                            <input
                              type="password"
                              className="form-control"
                              placeholder="Enter your code"
                              value={userVipCode}
                              onChange={(e) => setUserVipCode(e.target.value)}
                            />
                          </div>
                        </div>
                      )}

                      {initialState ? (
                        <button
                          className="btn btn-grad w-full btn-lg"
                          onClick={() => handleLogin()}
                        >
                          {loginLoader ? (
                            <Loader
                              type="Oval"
                              color="#FFFFFF"
                              height={30}
                              width={30}
                            />
                          ) : (
                            "Login"
                          )}
                        </button>
                      ) : (
                        <button
                          disabled={loading}
                          className="btn btn-grad w-full btn-lg"
                          onClick={() => handleRegister()}
                        >
                          {loading ? "Creating..." : "Register"}
                        </button>
                      )}
                      {initialState && (
                        <button
                          onClick={() => {
                            setShowMainscreen(false);
                            setShowEmailScreen(true);
                          }}
                          style={{
                            border: "none",
                            background: "transparent",
                            color: "#5D3AE4",
                            zIndex: 999,
                            position: "relative",
                          }}
                        >
                          Forgot password?
                        </button>
                      )}
                    </div>
                  </>
                )}

                {showEmailScreen && (
                  <>
                    <div className="box edit_box w-full space-y-20">
                      <div className="space-y-10">
                        <span className="nameInput">Email </span>
                        <div className="confirm">
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Enter email"
                            value={emailForReset}
                            onChange={(e) => setResetEmail(e.target.value)}
                          />
                        </div>
                      </div>

                      <button
                        disabled={loading}
                        className="btn btn-grad w-full btn-lg"
                        onClick={() => {
                          handleSendOtp();
                        }}
                      >
                        Send OTP
                      </button>
                    </div>
                  </>
                )}

                {showOtpScreen && (
                  <>
                    <div className="box edit_box w-full space-y-20">
                      <div className="space-y-10">
                        <span className="nameInput">OTP </span>
                        <div className="confirm">
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Enter your verification code"
                            value={otpForReset}
                            onChange={(e) => setOtpForReset(e.target.value)}
                          />
                        </div>
                      </div>

                      <button
                        disabled={loading}
                        className="btn btn-grad w-full btn-lg"
                        onClick={() => {
                          handleVerifyOtp();
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  </>
                )}

                {showPasswordScreen && (
                  <>
                    <div className="box edit_box w-full space-y-20">
                      <div className="space-y-10">
                        <span className="nameInput">New Password</span>
                        <div className="confirm">
                          <input
                            type="password"
                            className="form-control"
                            placeholder="Enter your new password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="space-y-10">
                        <span className="nameInput">Confirm Password</span>
                        <div className="confirm">
                          <input
                            type="password"
                            className="form-control"
                            placeholder="Enter your password again"
                            value={confirmPasswordForReset}
                            onChange={(e) =>
                              setConfirmPasswordForReset(e.target.value)
                            }
                          />
                        </div>
                      </div>

                      <button
                        disabled={
                          loading ||
                          confirmPasswordForReset !== newPassword ||
                          newPassword === ""
                        }
                        className="btn btn-grad w-full btn-lg"
                        onClick={() => {
                          handleUpdatePassword();
                        }}
                      >
                        Update
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
