import React from "react";

// All HOME PAGE ROUTES

import Home1 from "../views/homes/Home1";
import Home2 from "../views/homes/Home2";
import Home3 from "../views/homes/Home3";

//  Account inner pages
import ConnectWalllet from "../views/pages/account/ConnectWalllet";
import EditProfile from "../views/pages/account/EditProfile";
import Login from "../views/pages/account/Login";
import Profile from "../views/pages/account/Profile";
import Register from "../views/pages/account/Register";

//  Blog inner pages
import Blog from "../views/pages/blog/Blog";
import Article from "../views/pages/blog/Article";

//  item inner pages

import ItemDetails from "../views/pages/item/ItemDetails";
import Upload from "../views/pages/item/Upload";
import UploadType from "../views/pages/item/UploadType";

// NftPages
import Collections from "../views/pages/NftPages/Collections";
import Creators from "../views/pages/NftPages/Creators";
import LiveAuctions from "../views/pages/NftPages/LiveAuctions";
import Marketplace from "../views/pages/NftPages/Marketplace";
import Ranking from "../views/pages/NftPages/Ranking";
import UpcomingProjects from "../views/pages/NftPages/UpcomingProjects";

// other pages
import Activity from "../views/pages/others/Activity";
import Newsletter from "../views/pages/others/Newsletter";
import NoResults from "../views/pages/others/NoResults";
import PrivacyPolicy from "../views/pages/others/PrivacyPolicy";
import Chat from "../views/pages/Support/Chat";
import SubmitRequest from "../views/pages/Support/SubmitRequest";
import Faq from "../views/pages/Support/Faq";
import Forum from "../views/pages/forum/Forum";
import PostDetails from "../views/pages/forum/PostDetails";
import Contact from "../views/pages/Support/Contact";

// Route Specific
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import GuardedRoute from "./AuthGaurd";
import { useUser } from "../store/user";
import NotFound from "../views/NotFound";
const Routes = () => {
  const { isAuthenticated } = useUser();

  return (
    <>
      <Router>
        <Switch>
          <Route path="/Login" component={Login} />
          {isAuthenticated ? (
            <>
              <Route exact path="/" component={Home1} />
              <Route path="/home-2" component={Home2} />
              <Route path="/home-3" component={Home3} />
              {/* inner pages */}
              <Route path="/connect-wallet" component={ConnectWalllet} />
              <GuardedRoute path="/profile" component={Profile} />
              <GuardedRoute path="/edit-profile" component={EditProfile} />
              <GuardedRoute path="/register" component={Register} />
              <GuardedRoute path="/blog" component={Blog} />
              <GuardedRoute path="/article" component={Article} />
              <GuardedRoute
                path="/item-details/:network/:id"
                component={ItemDetails}
              />
              <GuardedRoute path="/upload" component={Upload} />
              <GuardedRoute path="/upload-type" component={UploadType} />
              <GuardedRoute path="/collections" component={Collections} />
              <GuardedRoute path="/creators" component={Creators} />
              <GuardedRoute path="/live-auctions" component={LiveAuctions} />
              <Route path="/marketplace" component={Marketplace} />
              <GuardedRoute path="/ranking" component={Ranking} />
              <GuardedRoute
                path="/upcoming-projects"
                component={UpcomingProjects}
              />
              <GuardedRoute path="/activity" component={Activity} />
              <GuardedRoute path="/newsletter" component={Newsletter} />
              <GuardedRoute path="/chat" component={Chat} />
              <GuardedRoute path="/submit-request" component={SubmitRequest} />
              <GuardedRoute path="/no-results" component={NoResults} />
              <GuardedRoute path="/faq" component={Faq} />
              <GuardedRoute path="/privacy-policy" component={PrivacyPolicy} />
              <GuardedRoute path="/forum" component={Forum} />
              <GuardedRoute path="/post-details" component={PostDetails} />
              <GuardedRoute path="/contact" component={Contact} />
              {/* <Route path="*" component={NotFound} /> */}
              {/* <Route component={Home1} /> */}
            </>
          ) : (
            <Redirect to="/Login" />
          )}
        </Switch>
      </Router>
    </>
  );
};

export default Routes;
