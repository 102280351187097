import { createSlice } from "@reduxjs/toolkit";
import Web3 from "web3";
import { creatoContractBid, tokenAddress } from "../../ethereum/contracts";
import { bidContract, coreContract } from "../../ethereum/contracts/coreLB";
import { notifyApprove } from "./helpers";
import { createTokenState } from "./types";

const initialState = {
  selectedCategory: "All",
  isOwner: false,
  createdTokenId: null,
  creatingTokenState: createTokenState.NULL,
  createLoading: false,
};

export const collectibleSlice = createSlice({
  name: "collectibles",
  initialState,
  reducers: {
    setCategory: (state, action) => {
      state.selectedCategory = action.payload;
    },
    setIsOwner: (state, action) => {
      state.isOwner = action.payload;
    },
    resetCreatingState: (state, action) => {
      state.creatingTokenState = createTokenState.NULL;
      state.createdTokenId = null;
    },
    setCreatedTokenId: (state, action) => {
      state.creatingTokenState = createTokenState.SUCCESS;
      state.createdTokenId = action.payload;
    },
    setFailureState: (state, action) => {
      state.createdTokenId = null;
      state.creatingTokenState = createTokenState.FAILED;
    },
    setCreateLoading: (state, action) => {
      state.createLoading = action.payload;
    },
  },
});

export const {
  setCategory,
  setIsOwner,
  resetCreatingState,
  setCreatedTokenId,
  setFailureState,
  setCreateLoading,
} = collectibleSlice.actions;

export default collectibleSlice.reducer;

export const createNewToken = (arg, dispatch) => {
  try {
    const web3 = arg.library;
    coreContract(web3, arg.chainId)
      .methods.addNewTokenAndSetThePrice(
        arg.tokenAmount,
        arg.isForSale,
        arg.price,
        arg.priceType,
        arg.royaltyPercentage,
        arg.startTime,
        arg.endTime
      )
      .send({ from: arg.address })
      .on("receipt", (res) => {
        dispatch(
          setCreatedTokenId(res.events.AddNewToken.returnValues.tokenId)
        );
        dispatch(setCreateLoading(false));
      })
      .on("transactionHash", (hash) => {
        console.log(hash);
      })
      .on("error", (err, res) => {
        console.log(err);
        dispatch(setFailureState());
        dispatch(setCreateLoading(false));
      });
  } catch (err) {
    console.log(err);
  }
};

export const purchaseHandler = ({
  tokenDetails,
  library,
  account,
  payable,
  chainId,
  setPurchaseStatus,
}) => {
  try {
    bidContract(library, chainId)
      .methods.buy(tokenDetails.token, tokenDetails.createdBy.account)
      .send({
        from: account,
        value: Web3.utils.toWei(payable.toString(), "ether"),
      })
      .on("error", (err, res) => {
        setPurchaseStatus("Failed");
      })
      .on("receipt", (res) => {
        // dispatch(postBids(updates));
        setPurchaseStatus("success");
      })
      .on("transactionHash", (hash) => {
        setPurchaseStatus("TransHash");
      });
  } catch (err) {
    console.log(err);
  }
};

export const setForSale = (arg) => {
  try {
    const res = coreContract(arg.library, arg.chainId)
      .methods.setIsForSale(true, arg.id)
      .send({ from: arg.account })
      .on("error", (err) => {
        return err;
      })
      .on("receipt", (res) => {
        return res;
      });

    return res;
  } catch (err) {
    console.log(err);
  }
};

export const placeBidHandler = ({
  fullAmount,
  token,
  library,
  account,
  chainId,
  payable,
  setBidStatus,
  updates,
}) => {
  try {
    bidContract(library, chainId)
      .methods.bid(fullAmount, token.token, token.createdBy.account)
      .send({
        from: account,
        value: Web3.utils.toWei(payable.toString(), "ether"),
      })
      .on("error", (err, res) => {
        setBidStatus("Failed");
      })
      .on("receipt", (res) => {
        // dispatch(postBids(updates));
        setBidStatus("success");
      })
      .on("transactionHash", (hash) => {
        setBidStatus("TransHash");
      });
  } catch (err) {
    console.log(err);
  }
};

export const setApproval = (arg) => {
  try {
    // const token = tokenAddress(arg.chainId);
    const res = coreContract(arg.library, arg.chainId)
      .methods.setApprovalForAll(creatoContractBid(arg.chainId), true)
      .send({ from: arg.account })
      .on("error", (err) => {
        return err;
      })
      .on("receipt", (res) => {
        notifyApprove();
        return res;
      });

    return res;
  } catch (err) {
    console.log(err);
  }
};

export const acceptBid = (arg) => {
  try {
    // const token = tokenAddress(arg.chainId);
    const res = bidContract(arg.library, arg.chainId)
      .methods.acceptBid(arg.tokenId, arg.account)
      .send({ from: arg.account })
      .on("error", (err) => {
        return err;
      })
      .on("receipt", (res) => {
        return res;
      });

    return res;
  } catch (err) {
    console.log(err);
  }
};
