import React from "react";
import EthereumLogo from "../../assets/img/icons/ethereumLogo.svg";
import PolygonLogo from "../../assets/img/icons/polygonmaticLogo.svg";
import "./index.scss";
import { Modal } from "react-bootstrap";
import { setCurrentChain, setCurrentRPC } from "../../store/user";
import { useDispatch } from "react-redux";

export const chainList = [
  {
    id: 1,
    name: "Ethereum",
    logo: EthereumLogo,
  },
  {
    id: 2,
    name: "Polygon",
    logo: PolygonLogo,
  },
];

const SelectChainModal = (props) => {
  const dispatch = useDispatch();
  return (
    <>
      <Modal className={`modal-chain`} {...props} centered>
        <Modal.Header closeButton style={{ borderBottom: "0" }}>
          <Modal.Title className="selectchain-title">Select Chain</Modal.Title>
        </Modal.Header>
        <Modal.Body className="custom-selectchain-body">
          {chainList &&
            chainList.map((list) => (
              <div
                key={list.id}
                className="selectchain-list"
                onClick={() => {
                  props.onHide();
                  dispatch(setCurrentChain(list.name));
                  dispatch(setCurrentRPC(list.id));
                }}
              >
                <img alt="Logo" src={list.logo} className="network-logo" />
                <span className="netwoork-title">{list.name}</span>
              </div>
            ))}
        </Modal.Body>
      </Modal>
    </>
  );
};
export default SelectChainModal;
