import React from "react";
import { useDispatch, } from "react-redux";

import { setCategory } from "../../../store/collectibles";
const MenuItems = [
  {
    icon: "🌍",
    title: "All",
  },
  {
    icon: "🕹",
    title: "Game",
  },
  {
    icon: "🌉",
    title: "Art",
  },
  {
    icon: "🤖",
    title: "Animation",
  },
  {
    icon: "🎶",
    title: "Music",
  },
  {
    icon: "👗",
    title: "Fashion",
  },
  {
    icon: "🍔",
    title: "Food",
  },
  {
    icon: "🏃🏻‍♀️",
    title: "Lifestyle",
  },
  {
    icon: "🎨",
    title: "Personal Art",
  },
  {
    icon: "✈️",
    title: "Travel",
  },
];
function MenuCategories() {
  const dispatch = useDispatch();

  return (
    <div>
      <ul className="menu_categories space-x-20">
        {/* <li>
          <Link className="color_brand">
            <span>🌍 All </span>
          </Link>
        </li> */}
        {MenuItems.map((val, i) => (
          <li key={i}>
            {" "}
            <div style={{ cursor: "pointer" }} onClick={() => dispatch(setCategory(val.title))}>
              <span className="pr-1 txt_lg "> {val.icon} </span>{" "}
              <span> {val.title}</span>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default MenuCategories;
