/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "reactjs-popup/dist/index.css";
import { COLLECTIBLES } from "../../queries";
import { useLazyQuery } from "@apollo/client";
import { useCollectibles } from "../../hooks";
import Loader from "react-loader-spinner";
import Cards from "./Card";
import { useUser } from "../../store/user";

function CardMarketplace({ filterValue, ownedByCreatorState }) {
  const [priceOrder, setPriceOrder] = useState("ASC");
  const [createdOrder, setCreatedOrder] = useState("ASC");
  const [whereQuery, setWhereQuery] = useState(null);
  const [filterData, setFilterData] = useState(true);
  // const [whereFunc, setWhereFunc] = useState();
  const { selectedCategory } = useCollectibles();
  const { currentRPC } = useUser();
  console.log("whereQuery", whereQuery);
  useEffect(() => {
    setWhereQuery(
      selectedCategory !== "All"
        ? {
            categories: {
              name: `${selectedCategory}`,
            },
            chainId: `${currentRPC === 1 ? "42" : "80001"}`,
          }
        : { chainId: `${currentRPC === 1 ? "42" : "80001"}` }
    );
  }, [selectedCategory, currentRPC]);

  const [getData, { loading, data }] = useLazyQuery(COLLECTIBLES, {
    variables: {
      sort: `${
        filterData ? `createdAt:${createdOrder}` : `price:${priceOrder}`
      }`,
      where: whereQuery,
    },
  });
  useEffect(() => {
    getData();
  }, [whereQuery, priceOrder, createdOrder, filterData]);

  useEffect(() => {
    switch (filterValue) {
      case "Oldest":
        setCreatedOrder("ASC");
        setPriceOrder("ASC");
        setFilterData(true);

        break;
      case "Latest":
        setPriceOrder("DESC");
        setCreatedOrder("DESC");
        setFilterData(true);

        break;
      case "Highest":
        setPriceOrder("DESC");
        setCreatedOrder("DESC");
        setFilterData(false);

        break;
      case "Lowest":
        setPriceOrder("ASC");
        setCreatedOrder("ASC");
        setFilterData(false);

        break;
      default:
        setCreatedOrder("ASC");
        setPriceOrder("ASC");
        setFilterData(false);
    }
  }, [filterValue]);

  return (
    <div>
      <div className="row mb-30_reset">
        {loading === true ? (
          <Loader type="Puff" color="#00BFFF" height={100} width={"100%"} />
        ) : (
          data?.collectibles.map((val, i) => (
            <Cards data={val} key={i} title={"Marketplace"} />
          ))
        )}
      </div>
    </div>
  );
}

export default CardMarketplace;
