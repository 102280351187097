import React from "react";
import { Link } from "react-router-dom";
import FooterLogo from "../../assets/img/logos/Logo.png";

function Footer() {
  return (
    <div>
      <footer className="footer__1">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 space-y-20">
              <div className="footer__logo">
                <Link to="/">
                  <img src={FooterLogo} alt="logo" id="logo_js_f" height="60" />
                </Link>
              </div>
              <p className="footer__text">
                Creatverse is a revolutionary social network that rewards users
                based on their interactions. Using the CREATVERE native token
                CREATO, platform users (both artists and fans) receive
                compensation for ratings, shares, likes, etc.
              </p>
              <div>
                <ul className="footer__social space-x-10 mb-40">
                  <li>
                    {" "}
                    <Link to="#">
                      {" "}
                      <i className="ri-facebook-line" />{" "}
                    </Link>
                  </li>
                  <li>
                    {" "}
                    <Link to="#">
                      {" "}
                      <i className="ri-medium-line" />{" "}
                    </Link>
                  </li>
                  <li>
                    {" "}
                    <Link to="#">
                      {" "}
                      <i className="ri-reddit-line" />{" "}
                    </Link>
                  </li>
                  <li>
                    {" "}
                    <Link to="#">
                      {" "}
                      <i className="ri-telegram-line" />{" "}
                    </Link>
                  </li>
                  <li>
                    {" "}
                    <Link to="#">
                      {" "}
                      <i className="ri-messenger-line" />{" "}
                    </Link>
                  </li>
                  <li>
                    {" "}
                    <Link to="#">
                      {" "}
                      <i className="ri-whatsapp-line" />{" "}
                    </Link>
                  </li>
                  <li>
                    {" "}
                    <Link to="#">
                      {" "}
                      <i className="ri-youtube-line" />{" "}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-6">
              <h6 className="footer__title">creatverse</h6>
              <ul className="footer__list">
                <li>
                  {" "}
                  <Link to="/"> About </Link>
                </li>
                <li>
                  {" "}
                  <Link to="home-2"> Disclaimers</Link>{" "}
                </li>
                <li>
                  {" "}
                  <Link to="home-3"> Staking Pools </Link>{" "}
                </li>
                <li>
                  {" "}
                  <Link to="marketplace"> Technical Support</Link>
                </li>
                <li>
                  {" "}
                  <Link to="marketplace"> Terms & Conditions</Link>
                </li>
                <li>
                  {" "}
                  <Link to="marketplace"> Privacy Policy </Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-2 col-6">
              <h6 className="footer__title">Assets</h6>
              <ul className="footer__list">
                <li>
                  {" "}
                  <Link to="profile"> Profile </Link>
                </li>
                <li>
                  {" "}
                  <Link to=" creators"> creators </Link>
                </li>
                <li>
                  {" "}
                  <Link to="collections"> Colletctions </Link>
                </li>
                <li>
                  {" "}
                  <Link to="Activity"> Activity</Link>{" "}
                </li>
              </ul>
            </div>
            <div className="col-lg-2 col-6">
              <h6 className="footer__title">Company</h6>
              <ul className="footer__list">
                <li>
                  {" "}
                  <Link to="uploadType"> Upload Types </Link>
                </li>
                <li>
                  {" "}
                  <Link to="upload"> Upload </Link>{" "}
                </li>
                <li>
                  {" "}
                  <Link to="connect-wallet"> Connect wallet</Link>{" "}
                </li>
              </ul>
            </div>
          </div>
          <p className="copyright text-center">
            © 2021 CREATVERSE. All rights reserved. Created with love by HAPPAX
            ENTERTAINMENT LAB (P) LTD{" "}
          </p>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
