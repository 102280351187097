import "./assets/css/plugins/bootstrap.min.css";
import "remixicon/fonts/remixicon.css";
import "./assets/scss/style.scss";
import Router from "./Router/routes";
import { useEffect } from "react";
import { HandleConnect } from "./connectors/helpers";
import { useWeb3React } from "@web3-react/core";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { useUser } from "./store/user";

function App() {
  const { activate, chainId, library } = useWeb3React();
  const { currentChain } = useUser();
  useEffect(() => {
    const currentWallet = localStorage.getItem("connectedWallet");
    if (currentWallet !== null && currentWallet !== undefined) {
      HandleConnect(currentWallet, activate);
    }
    if (window.ethereum) {
      window.ethereum.on("chainChanged", (_chainId) =>
        window.location.reload()
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log("chainId", library);

  async function changeWallet(_chainId) {
    console.log(window.ethereum, _chainId, "window");
    if (window.ethereum) {
      if (_chainId === 80001) {
        await window.ethereum.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainId: `0x${_chainId.toString(16)}`,
              chainName: "Matic Testnet",
              nativeCurrency: {
                name: "Matic",
                symbol: "matic",
                decimals: 18,
              },
              rpcUrls: ["https://rpc-mumbai.matic.today"],
              blockExplorerUrls: ["https://mumbai.polygonscan.com/"],
            },
          ],
        });
      } else if (_chainId === 42) {
        await window.ethereum.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainId: `0x${_chainId.toString(16)}`,
              chainName: "Kovan Testnet",
              nativeCurrency: {
                name: "ETH",
                symbol: "eth",
                decimals: 18,
              },
              rpcUrls: ["https://kovan.infura.io/v3/"],
              blockExplorerUrls: ["https://kovan.etherscan.io"],
            },
          ],
        });
      }
    }
  }
  useEffect(() => {
    if (currentChain === "Polygon") {
      if (chainId !== 80001) {
        console.log("polygon switching to polygon");
        changeWallet(80001);
      }
    }
    if (currentChain === "Ethereum") {
      if (chainId !== 42) {
        console.log("ethereum switching to ethereum");
        changeWallet(42);
      }
    }
  }, [currentChain, chainId]);

  return (
    <div className="App overflow-hidden">
      <Router />
      <ToastContainer />
    </div>
  );
}

export default App;
