import React from 'react'
import { Link } from 'react-router-dom'
const MenuItems = [
  {
    icon: "🕹",
    title: "Gaming"
  },
  {
    icon: "🌉",
    title: "Digital Art"
  },
  {
    icon: "🤖",
    title: "Animation"
  },
  {
    icon: "🎶",
    title: "Music"
  },
  {
    icon: "👗",
    title: "Fashion"
  },
  {
    icon: "🍔",
    title: "Food"
  },  {
    icon: "🏃🏻‍♀️",
    title: "Lifestyle"
  },
  {
    icon: "🎨",
    title: "Personal Art"
  },
  {
    icon: "✈️",
    title: "Travel"
  }
]
function MenuActivity() {
  return (
    <div>
      <ul className="menu_categories space-x-20">
        <li>
          <Link className="color_brand">

            <span>🌍 All </span>
          </Link>
        </li>
        {MenuItems.map((val, i) => (
          <li key={i}> <Link to="#">
            <span className="pr-1 txt_lg "> {val.icon} </span> {" "}
            <span> {val.title}</span>
          </Link>
          </li>
        ))}
      </ul>

    </div>
  )
}

export default MenuActivity
